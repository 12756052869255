import { SET_LIST_DATA } from '../../libs/Mutations';

import Api from '../../libs/Api';

export interface IState {
    data: any;
}

export const List = {
    state: () => ({
        data: {}
    } as IState),
    mutations: {
        [SET_LIST_DATA](state, data: any) {
            state.data = data;
        }
    },
    actions: {
        async getList(context, params: InterfaceListParams): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'getList',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_LIST_DATA, data.data.list);
                    }
                }
            )
        }
    }
};
