<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
//import VueRecaptcha from 'vue-recaptcha';
import CommonUtil from '../libs/CommonUtil'
import Mixin from '../libs/Mixin';

interface IState {
    isTabActive: string;
    staticErrors: object;
    loginId: string;
    loginPassword: string;
    errors: any;
    showRecaptcha: boolean;
    recaptchaToken: string;
}
export default defineComponent({
    mixins: [Mixin],
    components: {
        //VueRecaptcha
    },
    setup() {
        const state = reactive<IState>({
            isTabActive: '1',
            staticErrors: {},
            loginId: '',
            loginPassword: '',
            errors: {},
            showRecaptcha: false,
            recaptchaToken: '',
        });

        return {
            ...toRefs(state)
        };
    },
    unmounted() {
        this.$nextTick(() => {
            window.scrollTo(0, 0);
        });
    },
    methods: {
        submit(e: Event): void {
            e.preventDefault();
            this.errors = {};
            if (this.loginId === null || this.loginId.trim().length === 0) {
                this.errors.loginId = 'お名前IDは必須です。';
            }
            if (this.loginPassword === null || this.loginPassword.trim().length === 0) {
                this.errors.loginPassword = 'パスワードは必須です。';
            }
            // エラーがない場合
            if (Object.keys(this.errors).length === 0) {
                if (this.showRecaptcha) {
                    (this.$refs.recaptcha as any).execute();
                } else {
                    this.doLogin();
                }
            }
        },
        onCaptchaVerified(recaptchaToken: any): void {
            this.recaptchaToken = recaptchaToken;
            this.doLogin();
            (this.$refs.recaptcha as any).reset();
        },
        onCaptchaExpired(): void {
            this.recaptchaToken = '';
            (this.$refs.recaptcha as any).reset();
        },
        tabTrigger(num: string): void {
            this.isTabActive = num;
        },
        doLogin(): void {
            this.$store.dispatch('showLoading');

            const params: any = {
                loginId: this.loginId,
                loginPassword: this.loginPassword
            };

            if (this.showRecaptcha) {
                params.recaptchaToken = this.recaptchaToken;
            }

            this.$store.dispatch('login', params).then(response => {
                const data: any = response.data;
                if (!data.success) {
                    this.$store.dispatch('hideLoading');
                    if (CommonUtil.isObject(data.errorPoint)) {
                        Object.keys(data.errorPoint).forEach(key => {
                            if (this.hasOwnProperty(key)) {
                                this.errors[key] = data.errorPoint[key];
                            }
                        });
                    }
                    // パスワード変更対象
                    if (data.changedPassword === false) {
                        this.$store.dispatch('showModal', 'passwordChange');
                    } else {
                        // Todo:一旦、reCAPTCHAをOFFにする
                        // this.showRecaptcha = true;
                    }
                } else {
                    this.$store.dispatch('render', this.router);
                }
            }).catch(response => {
                this.$store.dispatch('hideLoading');
            });
        }
    }
});
</script>

<template>
<div>
    <main class="dac-main">
        <article class="dac-article">
            <section class="dac-section">
                <div class="dac-section__inner--lg">
                    <form method="post" @submit="submit">
                        <div class="dac-block--primary">
                            <h3 class="dac-block__heading">ログイン</h3>
                            <div class="dac-block-content--login">
                                <div class="dac-block-content__item">
                                    <div class="dac-block-content__sub">
                                        <div class="dac-form">
                                            <label class="dac-form-input__label" for="loginId"><span class="dac-form-input__text">お名前ID（会員ID）</span></label>
                                            <input type="text" name="loginId" id="loginId" v-model="loginId" placeholder="お名前ID(会員ID)" class="dac-form-input">
                                            <p class="dac-text--sm" v-if="errors.loginId"><span class="dac-text__alert">{{ errors.loginId }}</span></p>
                                            <label class="dac-form-input__label" for="loginPassword"><span class="dac-form-input__text">パスワード</span></label>
                                            <input type="password" name="loginPassword" id="loginPassword" v-model="loginPassword" placeholder="パスワード" class="dac-form-input">
                                            <p class="dac-text--sm" v-if="errors.loginPassword"><span class="dac-text__alert">{{ errors.loginPassword }}</span></p>
                                        </div><!-- /.dac-form -->
                                        <!--<VueRecaptcha
                                            v-if="showRecaptcha"
                                            ref="recaptcha"
                                            :sitekey="recaptchaSiteKey"
                                            badge="inline"
                                            size="invisible"
                                            @verify="onCaptchaVerified"
                                            @expired="onCaptchaExpired"
                                        ></VueRecaptcha>-->
                                        <div class="dac-block-content__btn">
                                            <button class="dac-btn-submit--lg" type="submit" @click="onclickEvar('eVar10', router.market + 'auc_' + pageName + '_login_loginbutton_onclick_201904')">ログイン</button>
                                        </div>
                                        <ul class="dac-text-indent--sm">
                                            <li class="dac-text-indent__item">・<a :href="'https://navi.onamae.com/auth/idreminder?btn_id=' + router.market + 'auc_' + pageName + '_login_idreminder_201904'" target="_blank" rel="noopener" class="dac-text__link">お名前IDを忘れた方はこちら</a></li>
                                            <li class="dac-text-indent__item">・<a :href="'https://navi.onamae.com/auth/pwreminder?btn_id=' + router.market + 'auc_' + pageName + '_login_pwreminder_201904'" target="_blank" rel="noopener" class="dac-text__link">パスワードを忘れた方はこちら</a></li>
                                        </ul>
                                    </div>
                                </div><!-- /.dac-block-content__item -->
                                <div class="dac-block-content__item">
                                    <div class="dac-block-content__sub">
                                        <p class="dac-text">お名前ID(会員ID)をお持ちでない方</p>
                                        <div class="dac-block-content__btn">
                                            <a class="dac-btn-primary--xl is-pc" target="_blank" rel="noopener" :href="getSignUpUrl + '&btn_id=' + router.market + 'auc_' + pageName + '_login_memberregistrationbutton_201904'">お名前.com会員登録(無料)</a>
                                            <a class="dac-btn-primary--lg is-sp" target="_blank" rel="noopener" :href="getSignUpUrl + '&btn_id=' + router.market + 'auc_' + pageName + '_login_memberregistrationbutton_201904'">お名前.com会員登録(無料)</a>
                                        </div>
                                    </div>
                                </div><!-- /.dac-block-content__item -->
                            </div><!-- /.dac-block-content--login -->
                        </div><!-- /.dac-block--primary -->
                    </form>
                </div>
            </section><!-- /.dac-section -->
        </article><!-- /.dac-article -->
    </main><!-- /.dac-main -->
</div>
</template>