<script lang="ts">
import { Component, defineComponent, reactive, toRefs } from 'vue';

import Login from '../Login.vue';
import List from '../List.vue';
import Error from '../Error.vue';
import MyPage from '../MyPage.vue';
import Detail from '../Detail.vue';

import {
    COMPONENT_LOGIN,
    COMPONENT_LIST,
    COMPONENT_ERROR,
    COMPONENT_MY_PAGE,
    COMPONENT_DETAIL,
} from '../../libs/Mutations';

interface IState {
    // noop.
}

export default defineComponent({
    setup() {
        const state = reactive<IState>({
            // noop.
        });

        return {
            ...toRefs(state)
        };
    },
    computed: {
        mainComponent(): Component | null {
            const component = this.$store.state.Common.mainComponent;
            if (component === COMPONENT_LOGIN) {
                return Login;
            } else if (component === COMPONENT_LIST) {
                return List;
            } else if (component === COMPONENT_ERROR) {
                return Error;
            } else if (component === COMPONENT_MY_PAGE) {
                return MyPage;
            } else if (component === COMPONENT_DETAIL) {
                return Detail;
            }
            return null;
        },
    }
});

</script>
<template>
    <div v-if="mainComponent">
        <component :is="mainComponent" />
    </div>
</template>
