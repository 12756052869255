<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import ItemModule from './modules/ItemModule.vue';
import HistoryListModule from './modules/HistoryListModule.vue';
import RecommendListModule from './modules/RecommendListModule.vue';
import Mixin from '../libs/Mixin';
import CommonUtil from '../libs/CommonUtil';

interface IState {
    showNoticeLosing: boolean;
    showNoticeHold: boolean;
    isAddCurrentPrice: boolean;
    isAuctionParam: boolean;
    isShowHelpModal: boolean;
}

let isFireShowNoticeEvar: boolean = false;

export default defineComponent({
    mixins: [Mixin],
    components: {
        ItemModule,
        HistoryListModule,
        RecommendListModule
    },
    setup() {
        const state = reactive<IState>({
            showNoticeLosing: true,
            showNoticeHold: true,
            isAddCurrentPrice: true,
            isAuctionParam: false,
            isShowHelpModal: true
        });

        return {
            ...toRefs(state)
        };
    },
    created() {
        this.fireShowNoticeEvar();
    },
    updated() {
        this.fireShowNoticeEvar();
        this.resetIsAddCurrentPrice();
    },
    methods: {
        hideNoticeLosing(): void {
            this.showNoticeLosing = false;
        },
        hideNoticeHold(): void {
            this.showNoticeHold = false;
        },
        resetNoticeShow(): void {
            this.showNoticeLosing = true;
            this.showNoticeHold = true;
        },
        resetIsAddCurrentPrice(): void {
            this.isAddCurrentPrice = false;
        },
        AddedCurrentPrice():void {
            this.isAddCurrentPrice = true;
        },
        fireShowNoticeEvar(): void {
            if (!isFireShowNoticeEvar) {
                if (
                    (
                        this.hold &&
                        this.showNoticeHold
                    ) ||
                    (
                        this.justLosing ||
                        (
                            !this.justLosing &&
                            this.losing &&
                            this.showNoticeLosing
                        )
                    )
                ) {
                    isFireShowNoticeEvar = true;
                }
            }
        },
        onClickFloatingBanner(): void {
            CommonUtil.events('event654');
        },
        onClickFloatingBannerClose(): void {
            this.isShowHelpModal = false;
        }
    },
    computed: {
        isEnd(): boolean {
            return this.$store.state.Item.item.isEnd === 1;
        },
        losing(): boolean {
            if (!this.isEnd && this.isLogin && this.$store.state.Item.item.entryFlg && this.$store.state.Item.item.hold === 0) {
                return true;
            }
            return false;
        },
        isEntered(): boolean {
            if (this.isLogin && this.$store.state.Item.item.entryFlg) {
                return true;
            }
            return false;
        },
        justLosing(): boolean {
            if (this.losing && this.$store.state.Bid.bidStatus) {
                return true;
            }
            return false;
        },
        hold(): boolean {
            if (!this.isEnd && this.isLogin && this.$store.state.Item.item.hold === 1) {
                return true;
            }
            return false;
        },
        getAuctionParamStatus(): boolean {
            const url = new URL(location.href);
            const bannerId = url.searchParams.get('banner_id');

            const patternGtldauction = new RegExp(`^gtldauction_parkingLP_`);
            const patternAuction = new RegExp(`^auction_parkingLP_`);

            if (bannerId && (patternGtldauction.test(bannerId) || patternAuction.test(bannerId))) {
                this.isAuctionParam = true;
            }

            return this.isAuctionParam;
        },
        eVar49Parameter(): string {
            // GT#183841
            const params: URLSearchParams = new URL(document.location.toString()).searchParams;
            const bannerId: string | null = params.get('banner_id');
            const btnId: string | null = params.get('btn_id');
            let parameter: string = '&eVar49=auction_diego_' + (this.isExistingUser ? 'exist' : 'new') + '_';

            if (bannerId) {
                parameter += 'banner_id_' + bannerId;
            } else if (btnId) {
                parameter += 'btn_id_' + btnId;
            }

            return parameter;
        },
        btnIdAuthed(): string {
            if (this.$store.state.Common.isLogin) {
               return 'login';
            }
            return 'notlogin';
        }
    },
});

</script>

<template>
<div>
    <main class="dac-main">
        <article class="dac-article">
            <section class="dac-section is-pc">
                <div class="dac-section__inner--lg">
                    <nav class="dac-breadcrumb">
                        <ul class="dac-breadcrumb__list">
                            <li class="dac-breadcrumb__item" v-if="router.market === 'jp'">
                                <a class="dac-breadcrumb__link"
                                   :href="[isDomainLevelLower ? '/campaign/auction/2?btn_id=jpauc_detail_breadcrumb_top_categorysecond' : '/campaign/auction/?btn_id=jpauc_detail_breadcrumb_top_201904' ]"
                                >オークショントップ</a>
                            </li>
                            <li class="dac-breadcrumb__item" v-if="router.market === 'other'">
                                <a class="dac-breadcrumb__link" :href="'/campaign/gtldauction/?btn_id=' + router.market + 'auc_detail_breadcrumb_top_202310'">オークショントップ</a>
                            </li>
                            <li class="dac-breadcrumb__item" v-else-if="router.market === 'gtld'">
                                <a class="dac-breadcrumb__link" href="/campaign/gtldauction/?btn_id=gtldauc_detail_breadcrumb_top_201904">オークショントップ</a>
                            </li>
                            <li class="dac-breadcrumb__item" v-else-if="router.market === 'sell'">
                                <a class="dac-breadcrumb__link" :href="linkUrl('list?btn_id=sellauc_detail_breadcrumb_top_201904')">ドメイン売買トップ</a>
                            </li>
                            <li class="dac-breadcrumb__item is-current">ドメイン詳細</li>
                        </ul>
                    </nav><!-- /.dac-breadcrumb -->
                </div>
            </section><!-- /.dac-section -->
            <section class="dac-section">
                <div class="dac-section__inner">
                    <ItemModule
                        :isEnd="isEnd"
                        :losing="losing"
                        :justLosing="justLosing"
                        :showNoticeLosing="showNoticeLosing"
                        :showNoticeHold="showNoticeHold"
                        :isEntered="isEntered"
                        @resetNoticeShow="resetNoticeShow"
                    ></ItemModule>
                </div>
            </section><!-- /.dac-section -->
            <HistoryListModule></HistoryListModule>
            <RecommendListModule></RecommendListModule>
            <section class="dac-section">
                <div class="dac-section__inner--lg">
                    <div class="is-pc">
                        <p class="dac-text--md">表示価格は「税込価格」です。</p>
                    </div>
                    <div class="is-sp">
                        <p class="dac-text--sm">表示価格は「税込価格」です。</p>
                    </div>
                </div><!-- /.dac-section__inner--lg -->
            </section><!-- /.dac-section -->

        </article><!-- /.dac-article -->
        <section v-if="getAuctionParamStatus" class="dac-section--auction">
            <div class="dac-section__inner--md">
                <h2 class="dac-block-auction__heading2">欲しかったドメインを<br class="is-sp"><span class="dac-text__alert">ドメインオークション</span>で<br class="is-sp">手に入れよう!!</h2>
                <div class="dac-block-auction__section">
                    <h3 class="dac-block-auction__heading3">ドメインオークションとは？</h3>
                    <div class="dac-block-auction__contents">
                        <div class="dac-block-auction__container">
                            <p class="dac-block-auction__text--about">ドメインの取得権利をオークション形式でご購入いただくサービスです。オークション出品中のドメインはどなたでも入札することができます。</p>
                        </div>
                        <span class="dac-block-auction__img--domainauction" aria-label="ドメインオークションロゴ" role="img"></span>
                    </div>
                </div>
                <div class="dac-block-auction__section">
                    <h3 class="dac-block-auction__heading3">ドメインオークションご利用の流れ</h3>
                    <ol class="dac-block-auction-list">
                        <li class="dac-block-auction-list__item">
                            <div class="dac-block-auction__contents">
                                <div class="dac-block-auction__container">
                                    <h4 class="dac-block-auction__heading4">①ドメインオークションの対象ドメイン名をチェック</h4>
                                    <p class="dac-block-auction__text--flow">ドメインオークションは毎月1日からスタート。まずは欲しいドメインがないか対象となっているドメインを確認しましょう。</p>
                                </div>
                                <span class="dac-block-auction__img--check_box" aria-label="チェックボックスアイコン" role="img"></span>
                            </div>
                        </li>
                        <li class="dac-block-auction-list__item">
                            <div class="dac-block-auction__contents">
                                <div class="dac-block-auction__container">
                                    <h4 class="dac-block-auction__heading4">②入札する</h4>
                                    <p class="dac-block-auction__text--flow">入札は300円から。あらかじめ予算額を入札しておく「自動入札機能」もおすすめです。</p>
                                </div>
                                <span class="dac-block-auction__img--step2" aria-label="入札アイコン" role="img"></span>
                            </div>
                        </li>
                        <li class="dac-block-auction-list__item">
                            <div class="dac-block-auction__contents">
                                <div class="dac-block-auction__container">
                                    <h4 class="dac-block-auction__heading4">③落札</h4>
                                    <p class="dac-block-auction__text--flow">オークション終了時に最高額を入札している方が落札者となります。</p>
                                </div>
                                <span class="dac-block-auction__img--good" aria-label="落札アイコン" role="img"></span>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
        <div class="dac-detail-floating-banner-jp-other" v-if="isShowHelpModal && abTestResult('188570') === 'b' && (router.market === 'jp' || router.market === 'other')">
            <a :href="'https://www.onamae.com/campaign/auction/help/?btn_id=jpauc_detail_floatingbanner_' + btnIdAuthed + '_help_202409' + eVar49Parameter" class="dac-detail-floating-banner-jp-other__pc" @click="onClickFloatingBanner" rel="noopener" target="_blank"><img :src="assets('images/modal_help_pc.png')" alt="入札前の不安解消！中古ドメインの全てがわかる！？ガイドはこちら"></a>
            <a :href="'https://www.onamae.com/campaign/auction/help/?btn_id=jpauc_detail_floatingbanner_' + btnIdAuthed + '_help_202409' + eVar49Parameter" class="dac-detail-floating-banner-jp-other__sp" @click="onClickFloatingBanner" rel="noopener" target="_blank"><img :src="assets('images/modal_help_sp.png')" alt="入札前の不安解消！中古ドメインの全てがわかる！？ガイドはこちら"></a>
            <a href="javascript:void(0)" class="dac-detail-floating-banner-jp-other__close" @click="onClickFloatingBannerClose"><img :src="assets('images/modal_help_close.png')" alt="閉じる"></a>
        </div>
    </main><!-- /.dac-main -->
    <template v-if="isDomainLevelLower">
        <div id="category_second" style="display: none;">category_second</div>
    </template>
</div>
</template>