<script lang="ts">
import CommonUtil from '../../libs/CommonUtil';
import { defineComponent, reactive, toRefs } from 'vue';
import cardValidator from 'card-validator';
import Mixin from '../../libs/Mixin';
interface IState {
    creditNumber: string,
    creditType: string,
    creditHolder: string,
    creditPeriodMonth: any,
    creditPeriodYear: any,
    securityCode: string,
    codeSize: number,
    errors: any,
    maxlengthCreditNumber: number
}

const DEFAULT_CODE_SIZE: number = 3;
const AVAILABLE_CREDIT_TYPES: string[] = [
    'visa',
    'mastercard',
    'american-express',
    'diners-club',
    'jcb'
];
const DEFAULT_MESSAGE: string = 'カード情報の照会ができませんでした。ご利用のカード会社にご確認ください。';
const MESSAGE_FROM_100_TO_102: string = 'カード番号に誤りがあります。';
const MESSAGE_FROM_110_TO_113: string = 'カード有効期限に誤りがあります。';
const MESSAGE_FROM_121_TO_122: string = 'セキュリティコードに誤りがあります。';
const MESSAGE_FROM_131_TO_132: string = 'カード名義人に誤りがあります。';
const CURRENT_YEAR: number = parseInt(CommonUtil.currentYear, 10);

let isInitMultiPayment: boolean = false;

export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
            creditNumber: '',
            creditType: '',
            creditHolder: '',
            creditPeriodMonth: 1,
            creditPeriodYear: CURRENT_YEAR + 1,
            securityCode: '',
            codeSize: DEFAULT_CODE_SIZE,
            errors: {},
            maxlengthCreditNumber: 19,
        });

        return {
            ...toRefs(state)
        };
    },
    mounted () {
        CommonUtil.doFixed(true);
    },
    unmounted() {
        CommonUtil.doFixed(false);
    },
    methods: {
        close(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('hideModal');
        },
        scrToggle(e: any): void {
            e.preventDefault();
        },
        submit(e: Event): void {
            e.preventDefault();
            this.errors = {};

            const creditInfo: any = cardValidator.number(this.creditNumber);
            if (this.creditHolder === null || this.creditHolder.length === 0) {
                this.errors.creditHolder = 'カード名義人は必須です。';
                if (!/^[a-zA-Z0-9 .\-,_]+$/.test(this.creditHolder)) {
                    this.errors.creditHolder = 'カード名義人を正しく入力してください。';
                }
            }
            if (this.securityCode === null || this.securityCode.length === 0) {
                this.errors.securityCode = 'セキュリティコードは必須です。'
            } else {
                if (!/^\d+$/.test(this.securityCode)) {
                    this.errors.securityCode = 'セキュリティコードは数字で入力してください。'
                } else {
                    if (CommonUtil.isObject(creditInfo.card) && creditInfo.card.code.size !== this.securityCode.length) {
                        this.errors.securityCode = 'セキュリティコードは' + creditInfo.card.code.size + '桁で入力してください。';
                    }
                }
            }

            if (!creditInfo.isValid) {
                this.errors.creditNumber = 'カード番号を正しく入力してください。';
            }

            // エラーがない場合
            if (Object.keys(this.errors).length === 0) {

                let creditPeriodMonth: string = this.creditPeriodMonth.toString();
                if (creditPeriodMonth.length === 1) {
                    creditPeriodMonth = '0' + creditPeriodMonth;
                }

                const expire: string = this.creditPeriodYear.toString() + creditPeriodMonth;
                const creditInfo: Object = {
                    cardno: this.creditNumber.replace(/\s/g, ''),
                    expire: expire,
                    securitycode: this.securityCode,
                    holdername: this.creditHolder
                };

                if (!isInitMultiPayment) {
                    Multipayment.init(this.$store.state.Common.shopId);
                    isInitMultiPayment = true;
                }

                Multipayment.getToken(creditInfo, (res: any) => {
                    // 成功
                    if (res.resultCode === '000') {
                        this.$store.dispatch('setCredit', {
                            crToken: res.tokenObject.token
                        }).then((response: any) => {
                            if (response.data.success) {
                                const item: any = this.$store.state.Item.item;
                                if (item.trafficType === 1) {
                                    this.$store.dispatch('showModal', 'buy');
                                } else {
                                    this.$store.dispatch('showModal', 'bid');
                                }

                                // クレジットカードありの状態にする
                                this.$store.dispatch('setCommonData', {
                                    cardCheckFlg: '1'
                                });
                            } else {
                                this.$store.dispatch('hideModal');
                            }
                        }).catch((response: any) => {
                            this.$store.dispatch('hideModal');
                        });
                    } else {
                        const errorCode: number = parseInt(res.resultCode, 10);
                        let errorPlace: string = 'creditNumber';
                        let errorMessage: string = DEFAULT_MESSAGE;
                        switch (errorCode) {
                            case 100:
                            case 101:
                            case 102:
                                errorMessage = MESSAGE_FROM_100_TO_102;
                                break;
                            case 110:
                            case 111:
                            case 112:
                            case 113:
                                errorMessage = MESSAGE_FROM_110_TO_113;
                                errorPlace = 'creditPeriod';
                                break;
                            case 121:
                            case 122:
                                errorMessage = MESSAGE_FROM_121_TO_122;
                                errorPlace = 'securityCode';
                                break;
                            case 131:
                            case 132:
                                errorMessage = MESSAGE_FROM_131_TO_132;
                                errorPlace = 'creditHolder';
                                break;
                        }
                        this.errors[errorPlace] = errorMessage;

                    }

                });
            }
        }
    },
    computed: {
        creditYears(): number[] {
            const years: number[] = [];

            let i: number;
            for (i = CURRENT_YEAR; i <= CURRENT_YEAR + 10; i++) {
                years.push(i);
            }
            return years;
        },
        creditMonths(): Object {
            const months: any = {};
            let start: number = 1;

            if (this.creditPeriodYear.toString() === CURRENT_YEAR.toString()) {
                start = parseInt(this.currentMonth, 10);
            }

            for (let i: number = start; i <= 12; i++) {
                let text: string = i.toString();
                if (i < 10) {
                    text = '0' + text;
                }
                months[i] = text;
            }
            if (parseInt(this.creditPeriodMonth, 10) < start) {
                this.creditPeriodMonth = start;
            }
            return months;
        },
        securityCodePlaceHolder(): string {
            let i: number = 0;
            let placeHolder: string = '';
            for (i; i < this.codeSize; ++i) {
                placeHolder += '0';
            }
            return placeHolder;
        }
    },
    watch: {
        creditNumber: {
            handler: function (value: string) {
                this.errors = {};
                const creditInfo: any = cardValidator.number(value);

                if (CommonUtil.isObject(creditInfo.card)) {
                    this.creditType = creditInfo.card.type;
                    this.codeSize = creditInfo.card.code.size;
                    if (AVAILABLE_CREDIT_TYPES.indexOf(creditInfo.card.type) === -1) {
                        this.errors.creditNumber = '使用できないカードです。';
                        this.creditType = '';
                        this.codeSize = DEFAULT_CODE_SIZE;
                        this.creditNumber = this.creditNumber.replace(/[^0-9]/g, '').replace(/(.{4})/g, '$1 ').trim()
                        this.maxlengthCreditNumber = 19;
                    }
                    if (this.creditType === 'visa' || this.creditType === 'mastercard' || this.creditType === 'jcb') {
                        this.creditNumber = this.creditNumber.replace(/[^0-9]/g, '').replace(/(.{4})/g, '$1 ').trim()
                        this.maxlengthCreditNumber = 19;
                    } else if (this.creditType === 'diners-club') {
                        this.creditNumber = this.creditNumber.replace(/[^0-9]/g, '').replace(/(\d{4})\s*(\d{6})?\s*(\d{4})?/, '$1 $2 $3').replace(/\s\s/g, ' ').trim();
                        this.maxlengthCreditNumber = 16;
                    } else if (this.creditType === 'american-express') {
                        this.creditNumber = this.creditNumber.replace(/[^0-9]/g, '').replace(/(\d{4})\s*(\d{6})?\s*(\d{5})?/, '$1 $2 $3').replace(/\s\s/g, ' ').trim();
                        this.maxlengthCreditNumber = 17;
                    }
                } else {
                    this.creditType = '';
                    this.creditNumber = this.creditNumber.replace(/[^0-9]/g, '').replace(/(.{4})/g, '$1 ').trim()
                    this.maxlengthCreditNumber = 19;
                }

                if (!creditInfo.isPotentiallyValid) {
                    this.errors.creditNumber = 'カード番号を正しく入力してください。';
                }
            }
        },
    }
});

</script>

<template>
<div>
    <aside class="dac-modal-emphasis is-fixed">
        <h3 class="dac-modal-emphasis__heading">クレジットカード登録<a href="javascript:void(0)" class="dac-modal-emphasis__close" @click="close($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_creditcardmodal_close_onclick_201904')"></a></h3>
        <div class="dac-modal-emphasis__content">
            <form class="dac-form" method="post" @submit="submit">
                <dl class="dac-form-table">
                    <dt class="dac-form-table__label--stackable"><label class="dac-form-input__label" for="creditNumber">カード番号</label></dt>
                    <dd class="dac-form-table__content">
                        <div class="dac-form-table__inner">
                            <input type="text" name="creditNumber" id="creditNumber" placeholder="例）0000 0000 0000 0000" class="dac-form-input--md" v-model="creditNumber" :maxlength="maxlengthCreditNumber">
                            <p class="dac-text--sm is-sp" v-if="errors.creditNumber"><span class="dac-text__alert">{{ errors.creditNumber }}</span></p>
                            <ul class="dac-image-card">
                                <li class="dac-image-card__item"><i class="dac-ico-visa" :class="{'is-selected':creditType === 'visa'}"></i></li>
                                <li class="dac-image-card__item"><i class="dac-ico-master" :class="{'is-selected':creditType === 'mastercard'}"></i></li>
                                <li class="dac-image-card__item"><i class="dac-ico-jcb" :class="{'is-selected':creditType === 'jcb'}"></i></li>
                                <li class="dac-image-card__item"><i class="dac-ico-diner" :class="{'is-selected':creditType === 'diners-club'}"></i></li>
                                <li class="dac-image-card__item"><i class="dac-ico-amex" :class="{'is-selected':creditType === 'american-express'}"></i></li>
                            </ul><!-- /.dac-image-card -->
                        </div><!-- /.dac-form-table__inner -->
                        <p class="dac-text--sm is-pc" v-if="errors.creditNumber"><span class="dac-text__alert">{{ errors.creditNumber }}</span></p>
                    </dd>
                </dl>
                <dl class="dac-form-table">
                    <dt class="dac-form-table__label--stackable"><label class="dac-form-input__label" for="creditHolder">カード名義人</label></dt>
                    <dd class="dac-form-table__content">
                        <input type="text" name="creditHolder" id="creditHolder" placeholder="例）TAKASHI ONAMAE" class="dac-form-input--md" v-model="creditHolder">
                        <p class="dac-text--sm" v-if="errors.creditHolder"><span class="dac-text__alert">{{ errors.creditHolder }}</span></p>
                    </dd>
                </dl>
                <dl class="dac-form-table">
                    <dt class="dac-form-table__label">有効期限</dt>
                    <dd class="dac-form-table__content">
                        <div class="dac-form-select">
                            <select class="dac-form-select__item" v-model="creditPeriodMonth">
                                <option v-for="(text, month) in creditMonths" :value="month">{{ text }}</option>
                            </select>
                        </div><!-- /.dac-form-select -->
                        <span class="dac-form-select__separate">/</span>
                        <div class="dac-form-select">
                            <select class="dac-form-select__item" v-model="creditPeriodYear">
                                <option v-for="year in creditYears" :value="year">{{ year }}</option>
                            </select>
                        </div><!-- /.dac-form-select -->
                        <p class="dac-text--sm" v-if="errors.creditPeriod"><span class="dac-text__alert">{{ errors.creditPeriod }}</span></p>
                    </dd>
                </dl>
                <dl class="dac-form-table">
                    <dt class="dac-form-table__label"><label class="dac-form-input__label" for="securityCode">セキュリティコード</label>
                    </dt>
                    <dd class="dac-form-table__content">
                        <input
                            type="password"
                            name="securityCode"
                            id="securityCode"
                            class="dac-form-input--sm"
                            size="5"
                            maxlength="4"
                            :placeholder="securityCodePlaceHolder"
                            autocomplete="cc-csc"
                            v-model="securityCode"
                        >
                        <p class="dac-text--sm" v-if="errors.securityCode"><span class="dac-text__alert">{{ errors.securityCode }}</span></p>
                    </dd>
                </dl>
                <div class="dac-modal__btn">
                    <button class="dac-btn-submit--md" type="submit" @click="onclickEvar('eVar10', router.market + 'auc_' + pageName + '_creditcardmodal_registration_onclick_201904')">登録する</button>
                </div><!-- /.dac-modal__btn -->
            </form><!-- /.dac-form -->
        </div><!-- /.dac-modal-emphasis__content -->
    </aside><!-- /.dac-modal-emphasis -->
    <div class="dac-modal__overlay is-fixed" @click="close"></div>
</div>
</template>