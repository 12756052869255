<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import {
    COMPONENT_LOGIN,
    COMPONENT_LIST,
    COMPONENT_ERROR,
    COMPONENT_MY_PAGE,
    COMPONENT_DETAIL,
} from './libs/Mutations';

import HeaderModule from './components/modules/HeaderModule.vue';
import FooterModule from './components/modules/FooterModule.vue';
import MainModule from './components/modules/MainModule.vue';
import ModalsModule from './components/modules/ModalsModule.vue';

import Mixin from './libs/Mixin';

interface IState {
    isHamburgerActive: boolean
}

export default defineComponent({
    mixins: [Mixin],
    components: {
        HeaderModule,
        FooterModule,
        MainModule,
        ModalsModule,
    },
    data() {
        return {
            COMPONENT_LOGIN,
            COMPONENT_LIST,
            COMPONENT_ERROR,
            COMPONENT_MY_PAGE,
            COMPONENT_DETAIL,
        };
    },
    setup() {
        const state = reactive<IState>({
            isHamburgerActive: false
        });
        
        return {
            ...toRefs(state)
        };
    },
    created() {
        this.$store.dispatch('render', this.router);
    },
    computed: {
        intro(): boolean {
            return this.$store.state.Common.intro;
        }
    }
});
</script>

<template>
    <div>
        <ModalsModule v-if="!intro" />
        <div class="dac-loader" v-if="intro">
            <div class="dac-loader__spinner"><img :src="assets('images/img_loader.gif')" alt="loading..."/></div>
        </div><!-- /.dac-loader -->
        <div class="dac-loader__contents" v-if="!intro">
            <div class="dac-main__outer">
                <HeaderModule />
                <MainModule />
                <FooterModule />
            </div><!-- /.dac-main__outer -->
        </div><!-- /.dac-loader__contents -->

        <notifications group="notifications" position="bottom left" />
    </div>
</template>