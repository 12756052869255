<script lang="ts">
import {defineComponent, reactive, toRefs} from 'vue';
import Mixin from '../../libs/Mixin';
import {DEFAULT_LINE_CNT, DEFAULT_PAGE_NO,} from '../../libs/Mutations';
import PaginationModule from './PaginationModule.vue';
import moment from 'moment';

interface IState {
    pageNo: number,
    lineCnt: number,
}

export default defineComponent({
    components: {
        PaginationModule
    },
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
            pageNo: DEFAULT_PAGE_NO,
            lineCnt: DEFAULT_LINE_CNT,
        });

        return {
            ...toRefs(state)
        };
    },
    methods: {
        convertShortDateFormat(date: string): string {
            return moment(date.replace(/\//g, '-')).locale('ja').format('YY/MM/DD');
        },
        convertShortTimeFormat(date: string): string {
            return moment(date.replace(/\//g, '-')).locale('ja').format('HH:mm');
        },
        changePage(pageNo: number): void {
            if (!isNaN(pageNo) && pageNo > 0 && pageNo <= this.totalPage) {
                this.pageNo = pageNo;
                const params: InterfaceListParams = {
                    pageNo: this.pageNo,
                    lineCnt: this.lineCnt,
                    itemId: this.router.itemId as string
                };
                this.$store.dispatch('getHistoryList', params);
            }
        },
        login(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('showModal', 'login');
        }
    },
    computed: {
        listData(): any {
            return this.$store.state.HistoryList.data;
        },
        totalPage(): number {
            const totalCount: number = parseInt(this.listData.totalCount, 10);
            return Math.ceil(totalCount / this.lineCnt);
        }
    }
});
</script>

<template>
    <section class="dac-section" v-if="!isDomainLevelLower && listData.items.length > 0">
        <div class="dac-section__inner--lg">
            <h2 class="dac-heading2--point">入札履歴
                <time class="dac-heading2--point__sub">({{ currentDate }}時点)</time>
            </h2>

                <div class="dac-panel">
                    <table class="dac-panel-table">
                        <thead class="dac-panel-table__header">
                        <tr>
                            <th class="dac-panel-table__heading--detail">入札者</th>
                            <th class="dac-panel-table__heading--detail">入札価格</th>
                            <th class="dac-panel-table__heading--detail">入札時間</th>
                            <th class="dac-panel-table__heading--detail">入札状況</th>
                        </tr>
                        </thead>
                        <tbody class="dac-panel-table__body">
                        <tr v-if="!isLogin">
                            <td colspan="4">
                                <a class="dac-text__link" href="javascript: void(0);" @click="login($event)">ログインして確認する</a>
                            </td>
                        </tr>
                        <tr v-else v-for="item in listData.items">
                            <th class="dac-panel-table__content">
                                <div v-if="item.isHoldSelfFlg">
                                    <p class="dac-note--winning__text--small">
                                        <span class="dac-ico-holder"><img :src="assets('images/icon-smile.svg')" alt=""></span>
                                        お客様
                                    </p>
                                </div>
                                <div v-else>
                                    <p class="dac-note--alert__text--small">
                                        <span class="dac-ico-holder"><img :src="assets('images/icon-lose.svg')" alt=""></span>
                                        他者様
                                    </p>
                                </div>
                            </th>
                            <td class="dac-panel-table__content"><span class="dac-panel-table__price">{{ numberFormat(item.bid) }}</span>円</td>
                            <td class="dac-panel-table__content">
                                <span class="dac-panel-table__date is-pc">{{ item.bidTime }}</span>
                                <span class="dac-panel-table__date is-sp">
                                    {{ convertShortDateFormat(item.bidTime) }}
                                    <br>
                                    {{ convertShortTimeFormat(item.bidTime) }}
                                </span>
                            </td>
                            <td class="dac-panel-table__content"><span class="dac-panel-table__status">{{ bidTypeName(item.bidType) }}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="is-pc">
                    <PaginationModule
                        :pageNo="pageNo"
                        :totalPage="totalPage"
                        :perPage="5"
                        :evarValue="'bidhistory'"
                        @changePage="changePage"
                    ></PaginationModule>
                </div>
                <div class="is-sp">
                    <PaginationModule
                        :pageNo="pageNo"
                        :totalPage="totalPage"
                        :perPage="5"
                        :evarValue="'bidhistory'"
                        @changePage="changePage"
                    ></PaginationModule>
                </div>
        </div><!-- /.dac-section__inner--lg -->
    </section><!-- /.dac-section -->
</template>