import { SET_END_DATE } from '../../libs/Mutations';

export interface IState {
    endDate: any;
}

export const Market = {
    state: () => ({
        endDate: null
    } as IState),
    mutations: {
        [SET_END_DATE](state, endDate: any): void {
            state.endDate = endDate;
        }
    },
    actions: {
        setEndDate(context, endDate: any): void{
            context.commit(SET_END_DATE, endDate);
        }
    }
};
