<script lang="ts">
import { Component, defineComponent } from 'vue';
import LoginModalModule from './LoginModalModule.vue';
import LogoutModalModule from './LogoutModalModule.vue';
import BidModalModule from './BidModalModule.vue';
import BuyModalModule from './BuyModalModule.vue';
import CreditCardModalModule from './CreditCardModalModule.vue';
import PasswordChangeModalModule from './PasswordChangeModalModule.vue';

interface IState {
}

export default defineComponent({
    computed: {
        currentModal(): string {
            return this.$store.state.Common.currentModal;
        },
        modalComponent(): Component | null {
            const currentModal = this.$store.state.Common.currentModal;
            if (currentModal === 'login') {
                return LoginModalModule;
            } else if (currentModal === 'logoutModal') {
                return LogoutModalModule;
            } else if (currentModal === 'passwordChange') {
                return PasswordChangeModalModule;
            } else if (currentModal === 'bid') {
                return BidModalModule;
            } else if (currentModal === 'creditCard') {
                return CreditCardModalModule;
            } else if (currentModal === 'buy') {
                return BuyModalModule;
            }
            return null;
        }
    },
});
</script>

<template>
<div v-if="currentModal">
    <component :is="modalComponent" />
</div>
</template>
