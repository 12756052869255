<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';

import Mixin from '../../libs/Mixin';
import CommonUtil from '../../libs/CommonUtil';

interface IState {
    // noop.
}

export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
            // noop.
        });

        return {
            ...toRefs(state)
        };
    },
    mounted () {
        CommonUtil.doFixed(true);
    },
    unmounted() {
        CommonUtil.doFixed(false);
    },
    methods: {

        scrToggle(e: any): void {
            e.preventDefault();
        },
        close(e: MouseEvent) {
            e.preventDefault();
            this.$emit('hideBeginnerModal');
        }
    }
});

</script>

<template>
<div>
    <aside :class="['dac-modal-beginner', 'is-fixed', isMobile ? 'is-sp' : '']">
        <div class="dac-modal-beginner__content">
            <template v-if="isMobile">
                <img v-if="router.market === 'jp' || router.market === 'other'" :src="assets('images/img_beginner-modal-sp.png')" alt=".JPドメインオークションへようこそ！" />
            </template>
            <template v-else>
                <img v-if="router.market === 'jp' || router.market === 'other'" :src="assets('images/img_beginner-modal.png')" alt=".JPドメインオークションへようこそ！" />
            </template>
            <a href="javascript: void(0);" class="dac-modal-beginner__btn" @click="close"></a>
        </div>
    </aside><!-- /.dac-modal-beginner -->
    <div class="dac-modal-beginner__overlay is-fixed" @click="close"></div>
</div>
</template>