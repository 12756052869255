import { SET_VIEW_DATA } from '../../libs/Mutations';

export interface IState {
    data: any[];
}

export const View = {
    state: () => ({
        data: []
    } as IState),
    mutations: {
        [SET_VIEW_DATA](state, data: any) {
            state.data = data;
        }
    },
    actions: {
    }
};