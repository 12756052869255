<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import { NEED_AUTH_PAGES } from '../../libs/Mutations';
import CommonUtil from '../../libs/CommonUtil';
import Mixin from '../../libs/Mixin';

interface IState {
    scrollPos: number,
    isHamburgerActive: boolean,
    statePage: string,
    isToggleHamburger: boolean,
}

export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
            scrollPos: 0,
            isHamburgerActive: false,
            statePage: '',
            isToggleHamburger: false,
        });
        
        return {
            ...toRefs(state)
        };
    },
    created() {
        this.getLocationUrl();
    },
    methods: {
        getLocationUrl(): void {
            const pageUrl: string = location.href;
            if(pageUrl.match(/mypage\/\watch-list/)) {
                this.statePage = 'watch-list';
            } else if(pageUrl.match(/mypage/ || /mypage\/\bid-list/)) {
                this.statePage = 'mypage';
            } else {
                this.statePage = '';
            }
        },
        hamburgerToggle(el?: any): void {
            this.isHamburgerActive = !this.isHamburgerActive;
            this.isToggleHamburger = !this.isToggleHamburger;

            if (this.isHamburgerActive) {
                CommonUtil.doFixed(true);
            } else {
                CommonUtil.doFixed(false);
            }
        },
        hamburgerBeforeEnter(el: any): void {
            el.style.height = '0';
        },
        hamburgerEnter(el: any): void {
            el.style.height = el.scrollHeight + 'px';
        },
        hamburgerBeforeLeave(el: any): void {
            el.style.height = el.scrollHeight + 'px';
        },
        hamburgerLeave(el: any): void {
            el.style.height = '0';
        },
        login(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('setWatchEventLogin', 'loginDefault');
            this.$store.dispatch('showModal', 'login');
            this.isHamburgerActive = !this.isHamburgerActive;
        },
        logout(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('logout').then(response => {
                if (response.data.success) {
                    // マイページからのログアウト時は商材ページへリダイレクトさせる
                    if (this.router.action === 'mypage') {
                        const pageName: string = (this.router.detail as string).replace(/-/g, '');
                        if (this.router.market === 'jp') {
                            location.href = '/campaign/auction/?btn_id=jpauc_mypage_' + pageName + '_headermenu_logoutbtn_top_201904';
                        } else if (this.router.market === 'gtld') {
                            location.href = '/campaign/gtldauction/?btn_id=jpauc_mypage_' + pageName + '_headermenu_logoutbtn_top_201904';
                        } else {
                            location.href = this.linkUrl('list?btn_id=' + this.router.market + 'auc_mypage_' + pageName + '_headermenu_logoutbtn_top_201904');
                        }
                    } else {
                        this.$store.dispatch('render', this.router);
                    }
                }
            });
            this.isToggleHamburger = !this.isToggleHamburger;
            this.isHamburgerActive = !this.isHamburgerActive;
        },
        logoutModal(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('showModal', 'logoutModal');
            this.isToggleHamburger = !this.isToggleHamburger;
            this.isHamburgerActive = !this.isHamburgerActive;
        },
        scrToggle(e: Event): void {
            e.preventDefault();
        },
    },
    computed: {
        isShowLoginButton(): boolean {
            if (!(this.isLogin || (!this.isLogin && NEED_AUTH_PAGES.indexOf(this.router.action) >= 0))) {
                return true;
            }
            return false;
        },
        isContentVisible(): boolean {
            return (this.router.market === 'jp' || this.router.market === 'other') && this.router.detail === 'detail';
        },
        environment(): string {
            return CommonUtil.environment;
        },
        eVar49Parameter(): string {
            // GT#183841
            const params: URLSearchParams = new URL(document.location.toString()).searchParams;
            const bannerId: string | null = params.get('banner_id');
            const btnId: string | null = params.get('btn_id');
            let parameter: string = '&eVar49=auction_diego_' + (this.isExistingUser ? 'exist' : 'new') + '_';

            if (bannerId) {
                parameter += 'banner_id_' + bannerId;
            } else if (btnId) {
                parameter += 'btn_id_' + btnId;
            }

            return parameter;
        },
        btnIdAuthed(): string {
            if (this.$store.state.Common.isLogin) {
                return 'login';
            }
            return 'notlogin';
        }
    }
});
</script>

<template>
    <div>
        <header class="dac-header">
            <div v-if="isMobile && isContentVisible" class="dac-header-overlay" :class="{ 'is-active': isToggleHamburger && isLogin }" @click="hamburgerToggle($event)"></div><!-- /.dac-header-overlay -->
            <div v-else class="dac-header-overlay" :class="{ 'is-active': isHamburgerActive }" @click="hamburgerToggle($event)"></div><!-- /.dac-header-overlay -->
            <div class="dac-header__inner">
                <div class="dac-header__logo">
                    <h1 class="dac-header-mypage__title {{ isContentVisible ? 'is-visible' : '' }}" v-if="router.market === 'jp' || router.market === 'other'">
                        <a href="//www.onamae.com/campaign/auction/" target="_blank" rel="noopener">
                            <img :src="assets('images/img_logo-jp-comnet.svg')" alt="ドメインオークション" />
                        </a>
                    </h1>
                    <h1 class="dac-header__title is-sell" v-else-if="router.market === 'sell'">
                        <img :src="assets('images/img_logo-sell.svg')" alt="ドメイン売買サービス"/>
                    </h1>
                    <a v-if="isContentVisible && !((router.market === 'jp' || router.market === 'other') && router.action === 'mypage')" :href="'/?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_onamaelogotop_201904'" target="_blank" rel="noopener" class="dac-header__link" :class="{'is-large':router.market === 'gtld' || router.market === 'other' || router.market === 'jp'}">
                        <img :src="assets('images/img_logo-onamae.svg')" alt="お名前.com" />
                    </a>
                    <a v-if="(router.market === 'jp' || router.market === 'other') && (router.action === 'mypage' || router.action === 'detail')" href="https://www.onamae.com" target="_blank" rel="noopener" class="dac-header-logo-onamae__link">
                        <img :src="assets('images/onamae_logo.svg')" alt="お名前.com" />
                    </a>
                </div><!-- /.dac-header__logo -->
                <nav class="dac-header-nav is-pc">
                    <ul class="dac-header-nav__list">
                        <template v-if="router.market === 'jp' || router.market === 'other'">
                            <template v-if="!isContentVisible">
                                <template v-if="isDomainLevelLower">
                                    <li class="dac-header-nav__item"><a :href="'/campaign/auction/2?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_categorysecond'" class="dac-header-nav__link">トップ</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/auction/guide/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_categorysecond'" class="dac-header-nav__link">サービス概要</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/auction/help/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_categorysecond' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                                </template>
                                <template v-else>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/auction/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904'" class="dac-header-nav__link">トップ</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/auction/guide/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_201904'" class="dac-header-nav__link">サービス概要</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/auction/help/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_202408' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                                </template>
                            </template>
                        </template>
                        <template v-else-if="router.market === 'gtld'">
                            <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904#what'" class="dac-header-nav__link">ドメインオークションとは</a></li>
                            <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_201904#flow'" class="dac-header-nav__link">ご利用の流れ</a></li>
                            <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_202408#questions' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                        </template>
                        <template v-else-if="router.market === 'sell'">
                            <li class="dac-header-nav__item"><a :href="linkUrl('list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904')" class="dac-header-nav__link">トップ</a></li>
                        </template>
                        <li class="dac-header-nav__item--menu" v-if="isLogin">
                            <div class="dac-header-login__menu dac-header-login__mypage" v-if="router.market === 'other' && router.action === 'mypage'">
                                <a
                                    :href="linkUrl('mypage/bid-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_bidlist_201904')"
                                    class="dac-header-nav__link"
                                    :class="{'is-current':router.detail ==='bid-list'}"
                                >マイページ</a>
                                <a
                                    :href="linkUrl('mypage/watch-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_watchlist_201904')"
                                    class="dac-header-nav__link"
                                    :class="{'is-current':router.detail ==='watch-list'}"
                                >ウォッチ</a>
                            </div>
                            <div class="dac-header-login__menu" v-else>
                                <a
                                    :href="linkUrl('mypage/watch-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_watchlist_201904')"
                                    class="dac-header-nav__link"
                                    :class="{'is-current':router.detail ==='watch-list'}"
                                >ウォッチ</a>
                                <a
                                    :href="linkUrl('mypage/bid-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_bidlist_201904')"
                                    class="dac-header-nav__link"
                                    :class="{'is-current':router.detail ==='bid-list'}"
                                >マイページ</a>
                            </div>
                        </li>
                        <li class="dac-header-nav__item">
                            <a
                                href="javascript:void(0)"
                                v-if="isShowLoginButton"
                                class="dac-btn-oval"
                                @click="login($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_notlogin_loginbtn_onclick_201904')"
                            ><span class="dac-btn-oval__text">ログイン</span></a>
                            <a
                                href="javascript:void(0)"
                                v-if="isLogin"
                                class="dac-btn-circle"
                                @click="logoutModal($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_login_logoutbtn_onclick_201904')"
                            ><span class="dac-btn-circle__text">{{ firstCharacter }}</span></a>
                        </li>
                    </ul>
                </nav><!-- /.dac-header-nav -->
                <div
                    v-if="isLogin && environment !== 'production'"
                    style="
                            position: absolute;
                            top: 100%;
                            right: 0;
                            background-color: #f6f6f6;
                            font-size: 11px;
                            padding: 2px 4px;
                            border: 1px solid #c8c8c8;
                            border-radius: 0 0 3px 3px;
                            opacity: 0.6;
                        "
                >nickname:{{ nickname }}</div>
                
                <button v-if="!isContentVisible" class="dac-header-nav__trigger" :class="{ 'is-active': isHamburgerActive }" @click="hamburgerToggle($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_menu_onclick_201904')">
                    <span class="dac-header-nav__trigger-item"></span>
                    <span class="dac-header-nav__trigger-item"></span>
                    <span class="dac-header-nav__trigger-item"></span>
                </button>
                <template v-else>
                    <template v-if="isMobile">
                
                        <button class="dac-header-nav__trigger" v-if="isToggleHamburger && isLogin" :class="{ 'is-active': isToggleHamburger && isLogin }" @click="hamburgerToggle($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_menu_onclick_201904')">
                            <span class="dac-header-nav__trigger-item"></span>
                            <span class="dac-header-nav__trigger-item"></span>
                            <span class="dac-header-nav__trigger-item"></span>
                        </button>
                        <ul class="dac-header__visible" :class="{ 'is-visible': isToggleHamburger && isLogin }">
                            <li v-if="isLogin">
                                <a
                                    href="javascript:void(0)"
                                    v-if="isLogin"
                                    class="dac-btn-circle"
                                    @click="hamburgerToggle($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_menu_onclick_201904')"
                                >
                                    <span class="dac-btn-circle__text">{{ firstCharacter }}</span>
                                </a>
                            </li>
                            <li v-if="isShowLoginButton">
                                <a
                                    href="javascript:void(0)"
                                    class="dac-btn-oval"
                                    @click="login($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_notlogin_loginbtn_onclick_201904')"
                                ><span class="dac-btn-oval__text">ログイン</span></a>
                            </li>
                        </ul>
                    </template>
                </template>
            </div><!-- /.dac-header__inner -->
            <template v-if="!isContentVisible">
                <transition name="dac-hamburger" @before-enter="hamburgerBeforeEnter" @enter="hamburgerEnter" @before-leave="hamburgerBeforeLeave" @leave="hamburgerLeave">
                    <nav class="dac-header-nav is-sp" :class="{ 'is-active': isHamburgerActive }" v-if="isHamburgerActive">
                        <ul class="dac-header-nav__list">
                            <li class="dac-header-nav__heading--member" v-if="isLogin">
                                会員メニュー
                                <a
                                    href="javascript:void(0)"
                                    v-if="isLogin"
                                    class="dac-btn-circle"
                                    @click="logoutModal($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_login_logoutbtn_onclick_201904')"
                                ><span class="dac-btn-circle__text">{{ firstCharacter }}</span></a>
                            </li>
                            <div class="dac-header-nav__mypage" v-if="router.market === 'other' && router.action === 'mypage'">
                                <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/bid-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_bidlist_201904')" class="dac-header-nav__link">マイページ</a></li>
                                <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/watch-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_watchlist_201904')" class="dac-header-nav__link">ウォッチリスト</a></li>
                            </div>
                            <div class="dac-header-nav__mypage" v-else>
                                <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/watch-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_watchlist_201904')" class="dac-header-nav__link">ウォッチリスト</a></li>
                                <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/bid-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_bidlist_201904')" class="dac-header-nav__link">マイページ</a></li>
                            </div>
                            <li v-if="router.market !== 'other'" class="dac-header-nav__heading">メニュー</li>
                            <template v-if="router.market === 'jp'">
                                <template v-if="isDomainLevelLower">
                                    <li class="dac-header-nav__item"><a :href="'/sp/campaign/auction/2?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_categorysecond'" class="dac-header-nav__link">トップ</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/sp/campaign/auction/guide/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_categorysecond'" class="dac-header-nav__link">サービス概要</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/sp/campaign/auction/help/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_categorysecond' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                                </template>
                                <template v-else>
                                    <li class="dac-header-nav__item"><a :href="'/sp/campaign/auction/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904'" class="dac-header-nav__link">トップ</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/sp/campaign/auction/guide/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_201904'" class="dac-header-nav__link">サービス概要</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/sp/campaign/auction/help/?btn_id=jpauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_202408' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                                </template>
                            </template>
                            <template v-else-if="router.market === 'gtld'">
                                <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904#what'" class="dac-header-nav__link">ドメインオークションとは</a></li>
                                <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_201904#flow'" class="dac-header-nav__link">ご利用の流れ</a></li>
                                <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_202408#questions' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                            </template>
                            <template v-else-if="router.market === 'sell'">
                                <li class="dac-header-nav__item"><a :href="linkUrl('list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904')" class="dac-header-nav__link">トップ</a></li>
                            </template>
                            <li class="dac-header-nav__heading" v-if="isShowLoginButton">
                                <a
                                    href="javascript:void(0)"
                                    class="dac-btn-oval"
                                    @click="login($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_notlogin_loginbtn_onclick_201904')"
                                ><span class="dac-btn-oval__text">ログイン</span></a>
                            </li>
                        </ul>
                    </nav><!-- /.dac-header-nav -->
                </transition>
            </template>
            <template v-else>
                <template v-if="isMobile">
                    <transition name="dac-hamburger" @before-enter="hamburgerBeforeEnter" @enter="hamburgerEnter" @before-leave="hamburgerBeforeLeave" @leave="hamburgerLeave">
                        <nav class="dac-header-nav is-sp" :class="{ 'is-active': isToggleHamburger }" v-if="isToggleHamburger && isLogin">
                            <ul class="dac-header-nav__list">
                                <li class="dac-header-nav__heading--member" v-if="isLogin">
                                    会員メニュー
                                    <a
                                        href="javascript:void(0)"
                                        v-if="isLogin"
                                        class="dac-btn-circle"
                                        @click="logoutModal($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_login_logoutbtn_onclick_201904')"
                                    ><span class="dac-btn-circle__text">{{ firstCharacter }}</span></a>
                                </li>
                                <div class="dac-header-nav__mypage" v-if="router.market === 'other' && router.action === 'mypage'">
                                    <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/bid-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_bidlist_201904')" class="dac-header-nav__link">マイページ</a></li>
                                    <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/watch-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_watchlist_201904')" class="dac-header-nav__link">ウォッチリスト</a></li>
                                </div>
                                <div class="dac-header-nav__mypage" v-else>
                                    <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/watch-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_watchlist_201904')" class="dac-header-nav__link">ウォッチリスト</a></li>
                                    <li class="dac-header-nav__item" v-if="isLogin"><a :href="linkUrl('mypage/bid-list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_mypage_bidlist_201904')" class="dac-header-nav__link">マイページ</a></li>
                                </div>
                                <li v-if="router.market !== 'other' && router.market !== 'jp'" class="dac-header-nav__heading">メニュー</li>
                                <template v-if="router.market === 'gtld'">
                                    <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904#what'" class="dac-header-nav__link">ドメインオークションとは</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_guiede_201904#flow'" class="dac-header-nav__link">ご利用の流れ</a></li>
                                    <li class="dac-header-nav__item"><a :href="'/campaign/gtldauction/?btn_id=gtldauc_' + pageName + '_headermenu_' + btnIdAuthed + '_help_202408#questions' + eVar49Parameter" class="dac-header-nav__link">ヘルプ</a></li>
                                </template>
                                <li v-else-if="router.market === 'sell'" class="dac-header-nav__item"><a :href="linkUrl('list?btn_id=' + router.market + 'auc_' + pageName + '_headermenu_' + btnIdAuthed + '_top_201904')" class="dac-header-nav__link">トップ</a></li>
                                <li class="dac-header-nav__heading" v-if="isShowLoginButton">
                                    <a
                                        href="javascript:void(0)"
                                        class="dac-btn-oval"
                                        @click="login($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_headermenu_notlogin_loginbtn_onclick_201904')"
                                    ><span class="dac-btn-oval__text">ログイン</span></a>
                                </li>
                            </ul>
                        </nav><!-- /.dac-header-nav -->
                    </transition>
                </template>
            </template>
        </header><!-- /.dac-header -->
    </div>
</template>