import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { notify } from "@kyvg/vue3-notification";

import CommonUtil from './CommonUtil';
import store from '../store';

class Api {

    private axios: AxiosInstance;

    private timeout: number = 1800000;

    private baseURL: string = '';

    private loading: boolean = true;

    static count: number = 0;

    constructor() {
        // apiの実行回数
        ++Api.count;

        const splitPathname: string[] = location.pathname.replace(/^\//, '').replace(/\/{2,}/g, '').split('/');

        this.baseURL = '/' + splitPathname[0] + '/api';

        // axiosのインスタンスを作成
        this.axios = axios.create({
            baseURL: this.baseURL,
            timeout: this.timeout,
            headers: {
                'X-CSRF-TOKEN': CommonUtil.csrfToken
            }
        });
    }

    private makeParams(params: object = {}): object
    {
        // marketIdは全リクエストに含める
        return Object.assign({}, {
            marketId: CommonUtil.router.marketId
        }, params);
    }

    public setLoading(value: boolean): this {
        this.loading = value;
        return this;
    }

    public get(path: string, params: object = {}, success?: (response: AxiosResponse) => void, error?: (response: AxiosResponse) => void, always?: () => void): AxiosPromise {
        if (this.loading) {
            store.dispatch('showLoading');
        }
        store.dispatch('startAjax');
        const request: AxiosPromise = this.axios.get(path, this.makeParams(params));
        // コールバック
        request
            .then(response => {
                const data: InterfaceResponseData = response.data;
                if (data.success) {
                    if (typeof data.nextUrl === 'string') {
                        location.href = data.nextUrl;
                    } else {
                        if (CommonUtil.isFunction(success)) {
                            (success as Function)(response);
                        }
                        if ((CommonUtil.isString(data.successMessage) && data.successMessage.length > 0) || CommonUtil.isArray(data.successMessage)) {
                            notify({
                                type: 'success',
                                group: 'notifications',
                                text:  data.successMessage
                            });
                        }
                    }
                } else {
                    if (CommonUtil.isString(data.errorMessage) || CommonUtil.isArray(data.errorMessage)) {
                        notify({
                            type: 'error',
                            group: 'notifications',
                            text: data.errorMessage
                        });
                    }
                }
            })
            .catch(response => {
                if (CommonUtil.isFunction(error)) {
                    (error as Function)(response);
                } else {
                    notify({
                        type: 'error',
                        group: 'notifications',
                        text: 'エラーが発生しました。ページの更新をお試しください'
                    });
                }
            })
            .then(() => {
                if (CommonUtil.isFunction(always)) {
                    (always as Function)();
                }
                if (this.loading) {
                    store.dispatch('hideLoading');
                }
                store.dispatch('stopAjax');
            });

        return request;
    }

    public post(path: string, params: object = {}, success?: (response: object) => void, error?: (response: object) => void, always?: Function): AxiosPromise {
        if (this.loading) {
            store.dispatch('showLoading');
        }
        store.dispatch('startAjax');
        const request: AxiosPromise = this.axios.post(path, this.makeParams(params));
        // コールバック
        request
            .then(response => {
                const data: InterfaceResponseData = response.data;
                if (data.success) {
                    if (typeof data.nextUrl === 'string') {
                        location.href = data.nextUrl;
                    } else {
                        if (CommonUtil.isFunction(success)) {
                            (success as Function)(response);
                        }
                        if ((CommonUtil.isString(data.successMessage) && data.successMessage.length > 0) || CommonUtil.isArray(data.successMessage)) {
                            notify({
                                type: 'success',
                                group: 'notifications',
                                text:  data.successMessage
                            });
                        }
                    }
                } else {
                    if (CommonUtil.isString(data.errorMessage) || CommonUtil.isArray(data.errorMessage)) {
                        notify({
                            type: 'error',
                            group: 'notifications',
                            text: data.errorMessage
                        });
                    }
                }
            })
            .catch(response => {
                if (CommonUtil.isFunction(error)) {
                    (error as Function)(response);
                } else {
                    notify({
                        type: 'error',
                        group: 'notifications',
                        text: 'エラーが発生しました。ページの更新をお試しください'
                    });
                }
            })
            .then(() => {
                if (CommonUtil.isFunction(always)) {
                    (always as Function)();
                }
                if (this.loading) {
                    store.dispatch('hideLoading');
                }
                store.dispatch('stopAjax');
            });
        return request;
    }
}

export default Api