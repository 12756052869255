<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';

import CommonUtil from '../../libs/CommonUtil';
import EventHub from '../../libs/EventHub';

import {
    DEFAULT_LINE_CNT,
    DEFAULT_PAGE_NO,
    LOGIN_SUCCESS_EVENT
} from '../../libs/Mutations';

import PaginationModule from './PaginationModule.vue';
import Mixin from '../../libs/Mixin';

interface IListData {
    items: any[],
    totalCount: any,
}

interface IState {
    checkedAucItemId: string[],
    pageNo: number,
    lineCnt: number,
    sortKey: string,
    sortType: string,
    isSortToggle: string,
    defaultColumn: string,
    domainColumn: string,
    matchType: string,
    domainName: string,
    excludeHyphens: boolean,
    excludeNumerals: boolean,
    domainEndDay: string,
    minLength: string,
    maxLength: string,
    showSearchOption: boolean,
    sortLabels: { [key: string]: string },
    spSortLabels: { [key: string]: string },
    matchTypes: { [key: string]: string },
}

const MATCH_TYPE_PERFECT: string = '1';
const MATCH_TYPE_PARTIAL: string = '2';
const MATCH_TYPE_FORWARD: string = '3';
const MATCH_TYPE_BACKWARD: string = '4';


const MATCH_TYPES: { [key: string]: string } = {
    [MATCH_TYPE_PERFECT]: '完全一致',
    [MATCH_TYPE_PARTIAL]: '部分一致',
    [MATCH_TYPE_FORWARD]: '前方一致',
    [MATCH_TYPE_BACKWARD]: '後方一致',
};

const SORT_LABELS: { [key: string]: string } = {
    domainNameMl: 'ドメイン名',
    aucTrafficTypeId: '種別',
    currentPrice: '料金（税込）',
    bidCount: '入札数',
    endDate: '申込期限',
};

const SP_SORT_LABELS: { [key: string]: string } = {
    domainNameMl: 'ドメイン名',
    aucTrafficTypeId: '種別',
    currentPrice: '価格',
    bidCount: '入札数',
    endDate: '期限日',
};

export default defineComponent({
    mixins: [Mixin],
    components: {
        PaginationModule
    },
    setup() {
        const state = reactive<IState>({
            checkedAucItemId: [],
            pageNo: DEFAULT_PAGE_NO,
            lineCnt: DEFAULT_LINE_CNT,
            sortKey: 'endDate',
            sortType: '1',
            isSortToggle: '0',
            defaultColumn: 'dac-panel-table__heading',
            domainColumn: 'dac-panel-table__heading--domain',
            matchType:  MATCH_TYPE_PARTIAL,
            domainName: '',
            excludeHyphens: false,
            excludeNumerals: false,
            domainEndDay: '',
            minLength: '',
            maxLength: '',
            showSearchOption: false,
            sortLabels: SORT_LABELS,
            spSortLabels: SP_SORT_LABELS,
            matchTypes: MATCH_TYPES,
        });

        return {
            ...toRefs(state)
        };
    },
    created() {
        this.changeCheckedAucItemId(this.listData);
    },
    methods: {
        changePage(pageNo: number): void {
            if (!isNaN(pageNo) && pageNo > 0 && pageNo <= this.totalPage) {
                this.pageNo = pageNo;

                this.getList();
            }
        },
        search(event: any): any {
            if (event instanceof Event && event.type === 'submit') {
                event.preventDefault();
            }
            this.pageNo = 1;
            this.getList();
        },
        getList(): void {
            this.$store.dispatch('getList', this.makeParams());
            this.checkedAucItemId.splice(0, this.checkedAucItemId.length);
        },
        sortToggle(num: string): void {
            this.isSortToggle = num;
        },
        addWatchList(itemId: string): void {
            if (this.isLogin) {
                this.$store.dispatch('addWatchList', itemId).then((response: any) => {
                    if (response.data.success) {
                        this.checkedAucItemId.push(itemId);
                        if (this.router.market === 'sell') {
                            CommonUtil.events('event309');
                        } else {
                            CommonUtil.events('event54');
                        }
                    }
                });
            } else {
                this.$store.dispatch('showModal', 'login');
                EventHub.once(LOGIN_SUCCESS_EVENT, () => {
                    this.$store.dispatch('addWatchList', itemId).then((response: any) => {
                        if (response.data.success) {
                            if (this.router.market === 'sell') {
                                CommonUtil.events('event309');
                            } else {
                                CommonUtil.events('event54');
                            }
                        }
                    });
                    this.$store.dispatch('render', this.router);
                });
            }
        },
        deleteWatchList(itemId: string): void {
            this.$store.dispatch('deleteWatchList', itemId).then((response: any) => {
                if (response.data.success) {
                    this.checkedAucItemId.splice(this.checkedAucItemId.indexOf(itemId), 1);
                }
            });
        },
        trafficType(trafficType: number, offerFlg: number): string {
            // marketId 4
            if (trafficType === 1) {
                if (offerFlg === 1) {
                    return '交渉可能';
                }
                return '即決価格';
            } else if (trafficType === 2) {
                return 'オークション';
            }
            return '-';
        },
        labelListButton(trafficType: number): string {
            if (trafficType === 1) {
                return '申し込む';
            }
            return '入札する';
        },
        toggleAddWatchList(itemId: string, e: Event): void {
            const currentTarget: any =  e.currentTarget;
            if (this.isLogin) {
                if (currentTarget.checked) {
                    this.$store.dispatch('addWatchList', itemId).then((response: any) => {
                        if (response.data.success) {
                            if (this.router.market === 'sell') {
                                CommonUtil.events('event309');
                            } else {
                                CommonUtil.events('event54');
                            }
                        }
                    });
                } else {
                    this.$store.dispatch('deleteWatchList', itemId);
                }
            } else {
                currentTarget.checked = false;
                this.checkedAucItemId.splice(this.checkedAucItemId.indexOf(itemId), 1);

                this.$store.dispatch('showModal', 'login');

                EventHub.once(LOGIN_SUCCESS_EVENT, () => {
                    this.$store.dispatch('addWatchList', itemId).then((response: any) => {
                        if (response.data.success) {
                            if (this.router.market === 'sell') {
                                CommonUtil.events('event309');
                            } else {
                                CommonUtil.events('event54');
                            }
                        }
                    });
                    this.$store.dispatch('render', this.router);
                });
            }
        },
        sort(sortKey: string): void {
            if (this.sortKey !== sortKey) {
                this.sortKey = sortKey;
                this.sortType = '1';
            } else {
                if (this.sortType === '1') {
                    this.sortType = '2';
                } else {
                    this.sortType = '1';
                }
            }
            this.pageNo = 1;
            this.$store.dispatch('getList', this.makeParams());
        },
        makeParams(): InterfaceListParams {
            const params: InterfaceListParams = {
                pageNo: this.pageNo,
                lineCnt: this.lineCnt
            };

            if (this.sortKey) {
                params.sortKey = this.sortKey;
                params.sortType = this.sortType;
            }

            let domainName = '';

            if (this.domainName) {
                domainName = this.domainName.toLowerCase();
                if (this.matchType) {
                    if (MATCH_TYPE_PARTIAL === this.matchType) {
                        domainName = '*' + domainName + '*';
                    } else if (MATCH_TYPE_FORWARD === this.matchType) {
                        domainName = domainName + '*';
                    } else if (MATCH_TYPE_BACKWARD === this.matchType) {
                        domainName = '*' + domainName;
                    }
                }
                params.domainName = domainName;
            }

            if (this.excludeHyphens) {
                params.excludeHyphens = 'true';
            }

            if (this.excludeNumerals) {
                params.excludeNumerals = 'true';
            }

            if (this.domainEndDay) {
                params.domainEndDay = this.domainEndDay;
            }

            if (this.minLength) {
                params.minLength = this.minLength;
            }

            if (this.maxLength) {
                params.maxLength = this.maxLength;
            }

            return params;
        },
        modifierClass(sortLabel: string) {
            if(sortLabel === 'ドメイン名') {
                return this.domainColumn;
            } else {
                return this.defaultColumn;
            }
        },
        clearSearch(): void {
            this.matchType = MATCH_TYPE_PARTIAL;
            this.domainName = '';
            this.excludeHyphens = false;
            this.excludeNumerals = false;
            this.domainEndDay = '';
            this.minLength = '';
            this.maxLength = '';
            this.getList();
        },
        changedMatchType(): void {
            let matchType: string = 'perfect';
            if (MATCH_TYPE_PARTIAL === this.matchType) {
                matchType = 'partial';
            } else if (MATCH_TYPE_FORWARD === this.matchType) {
                matchType = 'forward';
            } else if (MATCH_TYPE_BACKWARD === this.matchType) {
                matchType = 'backward';
            }
            this.onclickEvar('eVar10', this.router.market + 'auc_list_search_' + matchType + 'matching_onclick_201904');
            if (this.domainName.trim().length > 0) {
                this.getList();
            }
        },
        changeCheckedAucItemId(listData: any): void {
            this.checkedAucItemId.splice(0, this.checkedAucItemId.length);
            listData.items.forEach((item: any) => {
                if (item.isWatching === 1) {
                    this.checkedAucItemId.push(item.aucItemId);
                }
            });
        },
        isWatching(itemId: string) {
            return this.checkedAucItemId.indexOf(itemId) >= 0;
        },
    },
    computed: {
        listData(): IListData {
            return this.$store.state.List.data;
        },
        searchLength(): number[] {
            const length: number[] = [];
            let n: number = 3

            for (n; n <= 30; ++n) {
                length.push(n);
            }
            return length;
        },
        domainEndDays(): object {
            return {
                1: '当日',
                2: '2日以内',
                3: '3日以内',
                4: '4日以内',
                5: '5日以内',
            };
        },
        totalPage(): number {
            const totalCount: number = parseInt(this.listData.totalCount, 10);
            const totalPage: number = Math.ceil(totalCount / this.lineCnt);

            return totalPage;
        }
    },
    watch: {
        listData: {
            handler(listData: IListData): void {
                this.changeCheckedAucItemId(listData);
            },
            deep: true,
        }
    }
});
</script>

<template>
<div>
    <form @submit="search">
        <div class="dac-form-search">
            <input class="dac-form-search__input" type="text" name="domainName" id="domainName" v-model="domainName" placeholder="ドメイン名を入力" autocomplete="off">
            <a
                href="javascript: void(0);"
                class="dac-form-search__clear"
                @click="onclickEvar('eVar10', router.market + 'auc_list_search_searchclear_onclick_201904');clearSearch()"></a>
            <button
                class="dac-form-search__button"
                type="submit"
                @click="onclickEvar('eVar10', router.market + 'auc_list_search_searchbutton_onclick_201904')"
            >検索</button>
        </div><!-- /.dac-form-search -->
    </form>
    <div class="dac-form-search">
        <div class="dac-form-search__item" v-for="(label, key) in matchTypes">
            <input :id="key" class="dac-form-radio" :value="key" type="radio" v-model="matchType" @change="changedMatchType" name="match" value="all">
            <label class="dac-form-radio__label" :for="key">
                <span class="dac-form-radio__text">{{ label }}</span>
            </label>
        </div>
        <!-- <label class="dac-form-radio__label" v-for="(label, key) in matchTypes">
            <input class="dac-form-radio" :value="key" type="radio" v-model="matchType" name="match" value="all"><span class="dac-form-radio__text">{{ label }}</span>
        </label> -->
    </div><!-- /.dac-form-search -->
    <div class="is-pc">
        <div :class="['dac-panel', (0 === listData.items.length) ? 'is-flat' : '']">
            <table class="dac-panel-table--sort">
                <thead class="dac-panel-table__header--primary">
                <tr>
                    <th class="dac-panel-table__heading--check">
                        <!--<label class="dac-form-check__label" for="checkbox-table">
                            <input class="dac-form-check" type="checkbox" id="checkbox-table" @change="selectAll"><i class="dac-ico-watchall"></i>
                        </label>-->
                    </th>
                    <th v-for="(sortLabel, key) in sortLabels" :class="modifierClass(sortLabel)">
                        <template v-if="0 === listData.items.length">
                            {{ sortLabel }}
                        </template>
                        <template v-else>
                            <a
                                :class="['dac-panel-table__trigger', sortType === '1' ? 'is-current' : '']"
                                href="javascript: void(0);"
                                @click="sort(key);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_sort_' + key + '_onclick_201904')"
                            >
                                {{ sortLabel }}
                                <i class="dac-ico-sort" v-if="sortKey === key"></i>
                            </a>
                        </template>
                    </th>
                    <th class="dac-panel-table__heading--btn"></th>
                </tr>
                </thead>
                <tbody class="dac-panel-table__body">
                <tr v-for="item in listData.items">
                    <th class="dac-panel-table__content">
                        <label class="dac-form-check__label" :for="'checkbox' + item.aucItemId">
                            <input
                                class="dac-form-check"
                                type="checkbox"
                                :id="'checkbox' + item.aucItemId"
                                :value="item.aucItemId"
                                v-model="checkedAucItemId"
                                @change="toggleAddWatchList(item.aucItemId, $event)"
                                @click="onclickEvar('eVar10', router.market + 'auc_list_watchbutton_onclick_201904')"
                            >
                            <i class="dac-ico-watchlist"></i>
                        </label>
                    </th>
                    <td class="dac-panel-table__content"><a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_list_bid_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a></td>
                    <td class="dac-panel-table__content"><span class="dac-panel-table__text">{{ trafficType(item.trafficType, item.offerFlg) }}</span></td>
                    <td class="dac-panel-table__content">¥{{ numberFormat(item.currentPrice) }}</td>
                    <td class="dac-panel-table__content">{{ (item.trafficType === 2) ? item.bidCount : '-' }}</td>
                    <td class="dac-panel-table__content">
                        <span class="dac-panel-table__num">{{ convertJpDateFormat(item.endDate) }}</span>
                        <span class="dac-panel-table__time">{{ convertJpTimeFormat(item.endDate) }}</span>
                    </td>
                    <td class="dac-panel-table__content">
                        <span class="dac-btn-secondary--sm is-disabled" v-if="item.isEnd === 1">{{ labelListButton(item.trafficType) }}</span>
                        <a v-else :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_list_bid_button_201904')" class="dac-btn-secondary--sm">{{ labelListButton(item.trafficType) }}</a>
                    </td>
                </tr>
                <tr v-if="0 === listData.items.length">
                    <td class="dac-panel-table__content-empty" colspan="7">対象のドメインはありません。</td>
                </tr>
                </tbody>
            </table>
        </div><!-- /.dac-panel -->
        <p class="dac-text--md">表示価格は「税込価格」です。</p>
        <PaginationModule
            :pageNo="pageNo"
            :totalPage="totalPage"
            :perPage="5"
            @changePage="changePage"
        ></PaginationModule>
    </div><!-- /.is-pc -->
    <div class="is-sp">
        <div class="dac-tab-sort">
            <ul class="dac-tab-sort__list">
                <li class="dac-tab-sort__item" :class="{ 'is-active': (sortKey === key && listData.items.length > 0) }" v-for="(sortLabel, key) in spSortLabels">
                    <template v-if="0 === listData.items.length">
                            <span class="dac-tab-sort__heading">
                                {{ sortLabel }}
                            </span>
                    </template>
                    <template v-else>
                        <a
                            :class="['dac-tab-sort__heading', sortType === '1' ? 'is-current' : '']"
                            href="javascript: void(0);"
                            @click="sort(key);onclickEvar('eVar10', router.market + 'auc_' + pageName + 'sort_' + key + '_onclick_201904')"
                        >
                            {{ sortLabel }}
                            <i class="dac-ico-sort" v-if="sortKey === key"></i>
                        </a>
                    </template>
                </li>
            </ul>
            <div class="dac-panel-empty" v-if="listData.items.length === 0">
                対象のドメインはありません。
            </div>
            <div class="dac-tab-sort__content" v-else>
                <div class="dac-list-stackable">
                    <div v-for="item in listData.items" class="dac-list-stackable__item">
                        <a
                            class="dac-list-stackable__heading"
                            :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_list_bid_domain_201904')"
                        >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                        <div class="dac-list-stackable__body">
                            <dl class="dac-table-list--stackable">
                                <dt class="dac-table-list__title">種別:</dt>
                                <dd class="dac-table-list__content">{{ trafficType(item.trafficType, item.offerFlg) }}</dd>
                            </dl>
                            <dl class="dac-table-list--stackable">
                                <dt class="dac-table-list__title">価格:</dt>
                                <dd class="dac-table-list__content">¥{{ numberFormat(item.currentPrice) }}</dd>
                            </dl>
                            <dl class="dac-table-list--stackable">
                                <dt class="dac-table-list__title">入札数:</dt>
                                <dd class="dac-table-list__content">{{ (item.trafficType === 2) ? item.bidCount : '-' }}</dd>
                            </dl>
                            <dl class="dac-table-list--stackable">
                                <dt class="dac-table-list__title">期限日:</dt>
                                <dd class="dac-table-list__content">
                                    <span class="dac-panel-table__num">{{ convertJpDateFormat(item.endDate) }}</span>
                                    <span class="dac-panel-table__time">{{ convertJpTimeFormat(item.endDate) }}</span>
                                </dd>
                            </dl>
                        </div>
                        <div class="dac-list-stackable__footer">
                            <ul class="dac-text-indent--sm">
                                <li class="dac-text-indent__item" v-if="!isWatching(item.aucItemId)">
                                    <i class="dac-ico-star-active"></i>
                                    <a
                                        class="dac-text__link"
                                        href="javascript:void(0)"
                                        @click="addWatchList(item.aucItemId);onclickEvar('eVar10', router.market + 'auc_list_watchlist_add_button_onclick_201904')"
                                    >ウォッチリストに追加する</a>
                                </li>
                                <li class="dac-text-indent__item" v-else>
                                    <i class="dac-ico-star-active"></i>
                                    <a
                                        class="dac-text__link"
                                        href="javascript:void(0)"
                                        @click="deleteWatchList(item.aucItemId);onclickEvar('eVar10', router.market + 'auc_list_watchlist_delete_button_onclick_201904')"
                                    >追加済み</a>
                                </li>
                            </ul>
                            <span class="dac-btn-secondary--sm is-disabled" v-if="item.isEnd === 1">{{ labelListButton(item.trafficType) }}</span>
                            <a
                                v-else
                                :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_list_bid_button_201904')"
                                class="dac-btn-secondary--sm"
                            >{{ labelListButton(item.trafficType) }}</a>
                        </div>
                    </div>
                </div><!-- /.dac-list-stackable -->
            </div><!-- /.dac-tab-sort__content -->
        </div><!-- /.dac-tab-sort -->
        <p class="dac-text--sm">表示価格は「税込価格」です。</p>
        <PaginationModule
            :pageNo="pageNo"
            :totalPage="totalPage"
            :perPage="3"
            @changePage="changePage"
        ></PaginationModule>
    </div><!-- /.is-sp -->
</div>
</template>