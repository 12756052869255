import { SET_OFFER_LIST_DATA } from '../../libs/Mutations';

import Api from "../../libs/Api";

export interface IState {
    list: {
        totalCount: string;
        items: any[];
    }
}

export const Offer = {
    state: () => ({
        list: {
            totalCount: '0',
            items: []
        }
    } as IState),
    mutations: {
        [SET_OFFER_LIST_DATA](state, data: any) {
            state.list = data;
        }
    },
    actions: {
        async getOfferList(context, params: InterfaceListParams): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'getOfferList',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_OFFER_LIST_DATA, data.data.list);
                    }
                }
            )
        }
    }
};