<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
//import VueRecaptcha from 'vue-recaptcha';
import Mixin from '../../libs/Mixin';
import CommonUtil from '../../libs/CommonUtil';

interface IState {
    // noop.
}

export default defineComponent({
    mixins: [Mixin],
    components: {
        //VueRecaptcha
    },
    setup() {
        const state = reactive<IState>({
            // noop.
        });

        return {
            ...toRefs(state)
        };
    },
    mounted () {
        // modal fixed toggle
        CommonUtil.doFixed(true);
    },
    unmounted() {
        CommonUtil.doFixed(true);
    },
    methods: {

        scrToggle(e: any): void {
            e.preventDefault();
        },
        close(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('hideModal');
        },
        logout(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('showLoading');
            this.$store.dispatch('logout').then(response => {
                if (response.data.success) {
                    // マイページからのログアウト時は商材ページへリダイレクトさせる
                    if (this.router.action === 'mypage') {
                        const pageName: string = (this.router.detail as string).replace(/-/g, '');
                        if (this.router.market === 'jp') {
                            location.href = '/campaign/auction/?btn_id=jpauc_mypage_' + pageName + '_headermenu_logoutbtn_top_201904';
                        } else if (this.router.market === 'gtld') {
                            location.href = '/campaign/gtldauction/?btn_id=jpauc_mypage_' + pageName + '_headermenu_logoutbtn_top_201904';
                        } else {
                            location.href = this.linkUrl('list?btn_id=' + this.router.market + 'auc_mypage_' + pageName + '_headermenu_logoutbtn_top_201904');
                        }
                    } else {
                        this.$store.dispatch('render', this.router);
                    }
                }
            }).catch(response => {
                this.$store.dispatch('hideLoading');
            });
        }
    }
});

</script>

<template>
<div>
    <aside class="dac-modal--sm is-fixed">
        <div class="dac-modal__content">
            <p class="dac-text--center">
                <span class="dac-text__alert">ログアウト</span>します。<br>よろしいですか？
            </p>
            <div class="dac-layout-column--2">
                <div class="dac-layout-column__item">
                    <button
                        @click="logout"
                        class="dac-btn--max"
                    >はい</button>
                </div>
                <div class="dac-layout-column__item">
                    <button
                        class="dac-btn-primary--max"
                        @click="close"
                    >いいえ</button>
                </div>
            </div><!-- /.dac-layout-column--2 -->
        </div>
    </aside><!-- /.dac-modal-emphasis -->
    <div class="dac-modal__overlay is-fixed" @click="close"></div>
</div>
</template>