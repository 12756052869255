<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import Mixin from '../../libs/Mixin';

interface IState {
    // noop.
}

export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
            // noop.
        });

        return {
            ...toRefs(state)
        };
    },
    computed: {
        listData(): any[] {
            return this.$store.state.RecommendList.data;
        }
    },
});
/*
import * as moment from 'moment';

@Component({
  template: '#recommendListModuleTemplate',
})
export default class RecommendListModule extends Mixins<CommonMixin>(CommonMixin) {

  protected get listData(): any {
    return this.$store.state.RecommendList.data;
  }
}

 */
</script>

<template>
<template v-if="isDomainLevelLower">
    <section class="dac-section" v-if="listData.length > 0">
        <div class="dac-section__inner--lg">
            <h2 class="dac-heading2--point">オークションドメイン</h2>
            <div class="dac-panel">
                <table class="dac-panel-table">
                    <thead class="dac-panel-table__header">
                    <tr>
                        <th class="dac-panel-table__heading--detail">ドメイン</th>
                        <th class="dac-panel-table__heading--detail">ジャンル</th>
                        <th class="dac-panel-table__heading--detail">意味</th>
                        <th class="dac-panel-table__heading--detail"></th>
                    </tr>
                    </thead>
                    <tbody class="dac-panel-table__body">
                    <tr v-for="item in listData">
                        <td class="dac-panel-table__content">
                            <span class="dac-panel-table__date is-pc">{{ item.domainName }}</span>
                            <span class="dac-panel-table__date is-sp">
                                    {{ item.domainName }}
                                </span>
                        </td>
                        <td class="dac-panel-table__content">
                            <span class="dac-panel-table__date is-pc">{{ item.category }}</span>
                            <span class="dac-panel-table__date is-sp">
                                    {{ item.category }}
                                </span>
                        </td>
                        <td class="dac-panel-table__content">
                            <span class="dac-panel-table__date is-pc">{{ item.means }}</span>
                            <span class="dac-panel-table__date is-sp">
                                {{ item.means }}
                            </span>
                        </td>
                        <td class="dac-panel-table__content">
                            <a :href="linkUrl('/detail/' + item.itemId + '?btn_id={{ $market }}auc_list_bid_button_201904&category=second')"
                               class="dac-btn-secondary--sm"
                            >
                                入札する
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div><!-- /.dac-section__inner--lg -->
    </section><!-- /.dac-section -->
</template>
</template>