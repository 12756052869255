<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import CommonUtil from '../../libs/CommonUtil';
import AgreementSellModule from "./AgreementSellModule.vue";
import {
    TRAFFIC_TYPE_AUCTION,
    OFFER_FLAG_YES,
} from '../../libs/Mutations';
import Mixin from '../../libs/Mixin';

interface IState {
    // noop.
}

export default defineComponent({
    mixins: [Mixin],
    components: {AgreementSellModule},
    setup() {
        const state = reactive<IState>({
            // noop.
        });

        return {
            ...toRefs(state)
        };
    },
    mounted () {
        CommonUtil.doFixed(true);
    },
    unmounted() {
        CommonUtil.doFixed(false);
    },
    methods: {

        scrToggle(e: any): void {
            e.preventDefault();
        },
        close(e: MouseEvent) {
            e.preventDefault();
            this.$store.dispatch('hideModal');
        },
        submit(): void {

            const params: InterfaceBuyItemParams = {
                itemId: this.router.itemId,
                marketId: this.router.marketId,
                offerFlag: this.$store.state.Buy.offerFlag
            };

            if (this.$store.state.Buy.offerFlag === OFFER_FLAG_YES) {
                params.offerPrice = this.$store.state.Buy.offerPrice;
            }

            this.$store.dispatch('buyAuctionItem', params).then((response: any) => {
                if (response.data.success) {
                    if (this.$store.state.Buy.offerFlag === OFFER_FLAG_YES) {
                        CommonUtil.events('event92');
                    } else {
                        CommonUtil.events('event87');
                    }
                    this.$store.dispatch('render', this.router);
                }
            })
        }
    },
    computed: {
        item(): any {
            return this.$store.state.Item.item;
        },
        trafficTypeAuction(): boolean {
            if (this.item.trafficType === TRAFFIC_TYPE_AUCTION) {
                return true;
            }
            return false;
        },
        offerPrice(): any {
            return this.$store.state.Buy.offerPrice;
        },
        type(): string {
            if (this.$store.state.Buy.offerFlag === OFFER_FLAG_YES) {
                return '交渉可能';
            }
            return '即決価格';
        }
    }
});
</script>

<template>
<div>
    <aside class="dac-modal-emphasis is-fixed">
        <aside class="dac-modal-emphasis is-fixed">
            <h3 class="dac-modal-emphasis__heading">申し込み内容のご確認
                <a href="javascript:void(0)" class="dac-modal-emphasis__close" @click="close($event);onclickEvar('eVar10', router.market + 'auc_detail_applybidconfirmation_close_onclick_201904')"></a>
            </h3>
            <div class="dac-modal-emphasis__content">
                <div class="dac-note--modal">
                    <div class="dac-modal-emphasis__body">
                        <h2 class="dac-modal-emphasis__lead">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</h2>
                        <p class="dac-modal-emphasis__text">種別：{{ type }}</p>
                        <p class="dac-modal-emphasis__text--sm"><span class="dac-modal-emphasis__sub">{{ numberFormat(offerPrice) }}</span>円で申し込む</p>
                    </div><!-- /.dac-modal-emphasis__body -->
                    <div class="dac-modal__row">
                        <button
                            type="button"
                            class="dac-btn-secondary--lg-max"
                            :class="{'is-disabled': roleId !== '2'}"
                            @click="submit();onclickEvar('eVar10', router.market + 'auc_detail_applybidconfirmation_applycompletion_onclick_201904')"
                        >申し込む</button>
                    </div><!-- /.dac-modal__row -->
                </div><!-- /.dac-note--moodal -->
                <div class="dac-note-point">
                    <div class="dac-note-point__inner">
                        <AgreementSellModule />
                    </div><!-- /.dac-modal-emphasis__content -->
                </div><!-- /.dac-note-point -->
            </div><!-- /.dac-modal-emphasis__content -->
        </aside>

    </aside><!-- /.dac-modal-emphasis -->
    <div class="dac-modal__overlay is-fixed" @click="close"></div>
</div>
</template>