<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import ListModule from './modules/ListModule.vue';
import Mixin from '../libs/Mixin';
interface IState {

}
export default defineComponent({
    mixins: [Mixin],
    components: {
        ListModule
    },
    setup() {
        const state = reactive<IState>({

        });

        return {
            ...toRefs(state)
        };
    }
});

</script>

<template>
<div>
    <main class="dac-main">
        <article class="dac-article">
            <section class="dac-section">
                <div class="dac-section__inner--lg">
                    <h3 class="dac-heading-point" v-if="router.market === 'sell'">出品中ドメイン一覧</h3>
                    <h3 class="dac-heading-point" v-else>オークション中のドメイン</h3>
                    <ListModule></ListModule>
                </div>
            </section><!-- /.dac-section -->
        </article><!-- /.dac-article -->
    </main><!-- /.dac-main -->
</div>
</template>