import Api from '../../libs/Api';


export interface IState {
}

export const Payment = {
    state: () => ({
    } as IState),
    mutations: {
    },
    actions: {
        async setCredit(context, params: any): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'setCredit',
                params
            )
        }
    }
};