<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import CommonUtil from '../../libs/CommonUtil'
import EventHub from '../../libs/EventHub';
import { LOGIN_SUCCESS_EVENT } from '../../libs/Mutations';
import Mixin from '../../libs/Mixin';

interface IState {
    domainName: string,
    newPassword: string,
    errors: any,
}

export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
            domainName: '',
            newPassword: '',
            errors: {},
        });

        return {
            ...toRefs(state)
        };
    },
    methods: {
        submit(e: Event): void {
            e.preventDefault();
            this.errors = {};
            if (this.domainName === null || this.domainName.trim().length === 0) {
                this.errors.domainName = 'ドメイン名は必須です。';
            }
            if (this.newPassword === null || this.newPassword.trim().length === 0) {
                this.errors.newPassword = 'パスワードは必須です。';
            } else {
                if (this.newPassword.length < 8 || this.newPassword.length > 18) {
                    this.errors.newPassword = '8文字以上18文字以内で指定してください。';
                } else if(/^[0-9a-zA-Z,.!_\/`'()\[\]+\-=$#&@~?%*:;]+$/.test(this.newPassword) === false) {
                    this.errors.newPassword = '利用できない文字が含まれています。';
                }
            }
            if (Object.keys(this.errors).length === 0) {
                const params: InterfaceChangePasswordParams = {
                    domainName: this.domainName,
                    newPassword: this.newPassword
                };
                this.$store.dispatch('changePassword', params).then(response => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        if (EventHub.hasEvent(LOGIN_SUCCESS_EVENT)) {
                            this.$store.dispatch('render', this.router).then(response => {
                                EventHub.emit(LOGIN_SUCCESS_EVENT);
                            });
                        } else {
                            this.$store.dispatch('render', this.router);
                        }
                    } else {
                        if (CommonUtil.isObject(data.errorPoint)) {
                            Object.keys(data.errorPoint).forEach(key => {
                                if (this.hasOwnProperty(key)) {
                                    this.errors[key] = data.errorPoint[key];
                                }
                            });
                        }
                    }
                });
            }
        },
        close(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('hideModal');
            EventHub.off(LOGIN_SUCCESS_EVENT);
        }
    }
});

</script>

<template>
<div>
    <aside class="dac-modal-emphasis is-fixed">
        <h3 class="dac-modal-emphasis__heading">パスワード再設定
            <a
                href="javascript:void(0)"
                class="dac-modal-emphasis__close"
                @click="close($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_passresettingmodal_close_onclick_201904')"
            ></a>
        </h3>
        <div class="dac-modal-emphasis__content">
            <h4 class="dac-heading4--sm"><span class="dac-text__alert">ご利用を続けるにはパスワードの変更が必要です。</span></h4>
            <p class="dac-modal__text">お名前.comでは、セキュリティ強化の一環としてお名前IDをご利用の皆様にパスワードの定期的な変更をお願いしています。<br>ご本人様確認のためお持ちのドメイン名いずれかと、パスワードをご入力ください。</p>
            <form class="dac-form" method="post" @submit="submit">
                <dl class="dac-form-table">
                    <dt class="dac-form-table__label--stackable"><label class="dac-form-input__label" for="domainName">ドメイン名</label></dt>
                    <dd class="dac-form-table__content">
                        <input type="text" name="domainName" id="domainName" v-model="domainName" placeholder="例）onamae.com" class="dac-form-input--sm-max">
                        <p class="dac-text--sm" v-if="errors.domainName"><span class="dac-text__alert">@{{ errors.domainName }}</span></p>
                    </dd>
                </dl>
                <dl class="dac-form-table">
                    <dt class="dac-form-table__label--stackable"><label class="dac-form-input__label" for="newPassword">新しいパスワード</label></dt>
                    <dd class="dac-form-table__content">
                        <input type="password" name="newPassword" id="newPassword" v-model="newPassword" placeholder="8文字以上18文字以下でご入力ください" class="dac-form-input--sm-max">
                        <p class="dac-text--sm" v-if="errors.newPassword"><span class="dac-text__alert">@{{ errors.newPassword }}</span></p>
                    </dd>
                </dl>
                <div class="dac-modal__btn">
                    <button
                        class="dac-btn-submit--md"
                        type="submit"
                        @click="onclickEvar('eVar10', router.market + 'auc_' + pageName + '_passresettingmodalmodal_registration_onclick_201904')"
                    >変更する</button>
                </div><!-- /.dac-modal__btn -->
            </form><!-- /.dac-form -->

            <div class="dac-modal__footer--center">
                <p class="dac-text--sm">ドメイン名をお持ちでない方は<a :href="'https://navi.onamae.com/auth/pwreminder?btn_id=' + router.market + 'auc_' + pageName + '_passresettingmodalmodal_nodomain_201904'" class="dac-text__link" target="_blank" rel="noopener">こちら</a>よりお手続きください。</p>
            </div><!-- /.dac-modal__footer -->
        </div><!-- /.dac-modal-emphasis__content -->
    </aside><!-- /.dac-modal-emphasis -->
    <div class="dac-modal__overlay is-fixed" @click="close"></div>
</div>
</template>