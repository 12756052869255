import Api from '../../libs/Api';

import { SET_ITEM_DATA } from '../../libs/Mutations';

export interface IState {
    item: any;
}

export const Item = {
    state: () => ({
        item: []
    } as IState),
    mutations: {
        [SET_ITEM_DATA](state, item: any) {
            state.item = item;
        }
    },
    actions: {
        setItemData(context, item: any) {
            context.commit(SET_ITEM_DATA, item);
        },
        async getItemInfo(context, params: InterfaceListParams): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'itemInfo',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_ITEM_DATA, data.data.item);
                    }
                }
            )
        },
        async reloadItemInfo(context, params: InterfaceListParams): Promise<any> {
            const request: Api = new Api();
            return await request.setLoading(false).post(
                'itemInfo',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_ITEM_DATA, data.data.item);
                    }
                }
            )
        }
    }
};
