import { createApp, defineComponent } from 'vue';
import VueSmoothScroll from 'vue3-smooth-scroll';
import Notifications from '@kyvg/vue3-notification'
import store from './store';

import App from './App.vue';

const app = createApp(App);

app.use(store);
app.use(VueSmoothScroll);
app.use(Notifications);

app.mount('#app');
