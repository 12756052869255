import { SET_WATCH_LIST_DATA } from '../../libs/Mutations';
import Api from '../../libs/Api'

export interface IState {
    list: any;
}

export const WatchList = {
    state: () => ({
        list: {}
    } as IState),
    mutations: {
        SET_WATCH_LIST_DATA(state, data: any) {
            state.list = data;
        }
    },
    actions: {
        async getWatchList(context, params: any): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'getWatchList',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_WATCH_LIST_DATA, data.data.list);
                    }
                }
            )
        },
        async addWatchList(context, itemIds: string|string[]): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'addWatchList',
                {
                    itemId: itemIds
                }
            );
        },
        async deleteWatchList(context, itemIds: string|string[]): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'deleteWatchList',
                {
                    itemId: itemIds
                },
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_WATCH_LIST_DATA, data.data.list);
                    }
                }
            );
        }
    }
};
