import {
    SET_BID_LIST_DATA,
    SET_BID_PRICE,
    SET_BID_STATUS
} from '../../libs/Mutations';

import Api from "../../libs/Api";

export interface IState {
    list: {
        totalCount: string;
        items: any[];
    };
    bidPrice: number;
    bidStatus: boolean;
}

export const Bid = {
    state: () => ({
        list: {
            totalCount: '0',
            items: []
        },
        bidPrice: 0,
        bidStatus: false
    } as IState),
    mutations: {
        [SET_BID_LIST_DATA](state, data: any[]) {
            state.list = data;
        },
        [SET_BID_PRICE](state, bidPrice: number) {
            state.bidPrice = bidPrice;
        },
        [SET_BID_STATUS](state, bidStatus: boolean) {
            state.bidStatus = bidStatus;
        }
    },
    actions: {
        async getBidList(context, params: InterfaceListParams): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'getBidList',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_BID_LIST_DATA, data.data.list);
                    }
                }
            )
        },
        async bidAuctionItem(context, params: any): Promise<any> {
            context.commit(SET_BID_STATUS, true);
            const request: Api = new Api();
            return await request.post(
                'bidAuctionItem',
                params
            );
        },
        async getBidFairPrice(context, params: any): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'getBidFairPrice',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_BID_PRICE, data.data.item.nextBid);
                    }
                }
            )
        },
        setBidPrice(context, bidPrice: number): void {
            context.commit(SET_BID_PRICE, bidPrice);
        }
    }
};


