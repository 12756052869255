import { createStore } from 'vuex';

import { Common } from './modules/Common';
import { List } from './modules/List';
import { Item } from './modules/Item';
import { HistoryList } from './modules/HistoryList';
import { RecommendList } from './modules/RecommendList';
import { View } from './modules/View';
import { WatchList } from './modules/WatchList';
import { Bid } from './modules/Bid';
import { Buy } from './modules/Buy';
import { Payment } from './modules/Payment';
import { Market } from './modules/Market';
import { WinBid } from './modules/WinBid';
import { Offer } from './modules/Offer';

export default createStore({
    modules: {
        Common,
        List,
        Item,
        HistoryList,
        RecommendList,
        View,
        WatchList,
        Bid,
        Buy,
        Payment,
        Market,
        WinBid,
        Offer,
    }
})