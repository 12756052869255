<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import Mixin from '../../libs/Mixin';
import CommonUtil from "@/libs/CommonUtil";

interface IState {
    // noop.
}

export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({
        });
        
        return {
            ...toRefs(state)
        };
    },
    unmounted() {
        CommonUtil.doFixed(false);
    }
});

</script>

<template>
    <div>
        <div class="dac-loader">
            <div class="dac-loader__spinner"><img :src="assets('images/img_loader.gif')" alt="loading..."/></div>
        </div><!-- /.dac-loader -->
    </div>
</template>