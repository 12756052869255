import { defineComponent } from 'vue';

import moment from "moment/moment";
import CommonUtil from './CommonUtil';

interface IData {
    router: InterfaceRouter
}

export default defineComponent({
    data() {
        return {
            router: CommonUtil.router
        } as IData;
    },
    methods: {
        setRouterDetail(detail: string): void {
            this.router.detail = detail;
        },
        viewData(prop: string): any {
            if (this.$store.state.View.data.hasOwnProperty(prop)) {
                return this.$store.state.View.data[prop];
            }
            return null;
        },
        sitePath(): string {
            return CommonUtil.sitePath();
        },
        assets(path: string = ''): string {
            if (path.length > 0 && !/^\//.test(path)) {
                path = '/' + path;
            }

            return CommonUtil.sitePath() + '/assets' + path;
        },
        numberFormat(num: any): string {
            if (typeof num !== 'string') {
                num = num.toString();
            }
            return num.replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
        },
        linkUrl(suffix: string = ''): string {
            if (suffix.length > 0 && /^\//.test(suffix) === false) {
                suffix = '/' + suffix;
            }
            return CommonUtil.sitePath() + '/' + CommonUtil.router.market + suffix;
        },
        bidTypeName(bidType: string): string {
            const bidTypes: any = {
                'manual': '入札',
                'auto': '自動入札'
            };
            if (bidTypes.hasOwnProperty(bidType)) {
                return bidTypes[bidType];
            }
            return '-';
        },
        convertJpDateFormat(date: string): string {
            return moment(date).format('YYYY/MM/DD');
        },
        convertJpTimeFormat(date: string): string {
            return moment(date).format('HH:mm');
        },
        convertJpFullDateFormat(date: string): string {
            return moment(date).format('YYYY/MM/DD HH:mm');
        },
        convertToYear(date: string): string {
            return moment(date).format('YYYY');
        },
        remainTimeJp(endDate: string, format: string = 'DD日HH時mm分'): object {
            const now = moment();
            const diff = moment(endDate).diff(now);
            const duration: any = moment.duration(diff);
            const days    = Math.floor( duration.asDays() );
            const hours   = duration.hours();
            const minutes = duration.minutes();

            return {
                days: days,
                hours: hours,
                minutes: minutes
            }
        },
        length(target: any): number {
            if (CommonUtil.isArray(target) || CommonUtil.isString(target)) {
                return target.length;
            } else if (CommonUtil.isObject(target)) {
                return Object.keys(target).length;
            }
            return 0;
        },
        abTestResult(abKey: string): any {
            return CommonUtil.abTestResult(abKey);
        },
        onclickEvar(evar: string, value: string): void {
            let _s: any = s_gi(CommonUtil.evarContainer);
            _s.events = '';
            _s.linkTrackVars = evar;
            _s.purchaseID = '';
            _s[evar] = value;
            try {
                _s.tl(true, 'o', evar);
                if (CommonUtil.globalData.environment !== 'production') {
                    try {
                        console.log('Container:' + CommonUtil.evarContainer + ',fired evar:' + evar + ',' + ' value:' + value);
                    } catch (e) {
                        // 何もしない
                    }
                }
            } catch (e) {

            }


        },
    },
    computed: {
        isLogin(): boolean {
            return this.$store.state.Common.isLogin;
        },
        nickname(): null|string {
            return this.$store.state.Common.nickname;
        },
        firstCharacter(): null|string {
            return this.$store.state.Common.firstCharacter;
        },
        userName(): null|string {
            return this.$store.state.Common.userName;
        },
        roleId(): null|string {
            return this.$store.state.Common.roleId;
        },
        loading(): boolean {
            return this.$store.state.Common.loading;
        },
        isExistingUser(): boolean {
            return this.$store.state.Common.isExistingUser;
        },
        currentDate(): null|string {
            return moment(this.$store.state.Common.date).format('YYYY/MM/DD HH:mm:ss');
        },
        currentModal(): any {
            return this.$store.state.Common.currentModal;
        },
        isShowModal(): boolean {
            return this.$store.state.Common.currentModal !== null;
        },
        currentYear(): string {
            return moment(this.$store.state.Common.date).format('YYYY');
        },
        currentMonth(): string {
            return moment(this.$store.state.Common.date).format('MM');
        },
        currentDay(): string {
            return moment(this.$store.state.Common.date).format('MM');
        },
        recaptchaSiteKey() {
            return '6LfWSpcUAAAAAEY-m-wGmVhJh5OX0gKxGzKpILzU';
        },
        getSignUpUrl(): string {
            let url: string = 'https://account.onamae.';
            if (/\.com$/.test(window.location.hostname)) {
                url += 'com';
            } else {
                url += 'test';
            }

            const parameters: any = {
                mode: "auction",
                market: this.router.market,
                action: this.router.action,
            };

            if (this.router.action === 'detail') {
                parameters.item_id = this.router.itemId;
            }

            if (CommonUtil.isString(this.router.detail)) {
                parameters.detail = this.router.detail;
            }

            let queries: string[] = [];

            Object.keys(parameters).forEach((key) => {
                queries.push(key + "=" + parameters[key]);
            });

            return url + '/accountCreate?' + queries.join('&');
        },
        isMobile(): boolean {
            return CommonUtil.isMobile;
        },
        isDomainLevelLower(): boolean {
            if (
                CommonUtil.router.market === 'jp' &&
                location.search &&
                location.search.match(/(?:\?|&)category=second/)
            ) {
                return true;
            }
            return false;
        },
        pageName(): string {
            let action: string = this.router.action as string;
            if (action === 'mypage') {
                if (this.router.detail) {
                    action += '_' + this.router.detail.replace(/-/g, '');
                } else {
                    action += '_direct';
                }
            }
            return action;
        },
    }
});

