<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import Mixin from '../../libs/Mixin';

interface IState {
    // noop.
}

export default defineComponent({
    mixins: [Mixin],
    props: {
        pageNo: {
            type: Number,
            required: true
        },
        totalPage: {
            type: Number,
            required: true
        },
        evarValue: {
            type: String,
            required: false
        },
        perPage: {
            type: Number,
            required: true,
        }
    },
    setup() {
        const state = reactive<IState>({
            // noop.
        });

        return {
            ...toRefs(state)
        };
    },
    methods: {
        prevPage(e: MouseEvent): void {
            e.preventDefault();
            if (this.pageNo > 1) {
                const pageNo: any = this.pageNo - 1;
                this.$emit('changePage', pageNo);
            }
        },
        nextPage(e: MouseEvent): void {
            e.preventDefault();
            if (this.pageNo < this.totalPage) {
                const pageNo: any = this.pageNo + 1;
                this.$emit('changePage', pageNo);
            }
        },
        selectPage(pageNo: number): void {
            this.$emit('changePage', pageNo);
        }
    },
    computed: {
        pages(): number[] {
            const pages: number[] = [];
            const segment: number = Math.floor(this.perPage / 2);

            let start: number = this.pageNo - segment;
            start = start > 0 ? start : 1;

            let end: number = start > 1 ? this.pageNo + segment : this.perPage;
            start = this.totalPage < end ? start - (end - this.totalPage) : start;

            let i: number = start;
            for (i; i <= end; ++i) {
                if (i > 0 && i <= this.totalPage) {
                    pages.push(i);
                }
            }

            return pages;
        },
        showFirstPageBtn(): boolean {
            /*
            const segment: number = Math.floor(this.perPage / 2);
            let start: number = this.pageNo - segment;
            if (start >= Math.floor(this.perPage / 2)) {
                return true;
            }
            */
            return false;
        },
        showLastPageBtn(): boolean
        {
            if (this.pages.length > 0) {
                const end: number = this.pages[this.pages.length - 1];
                if (this.totalPage > end) {
                    return true;
                }
            }
            return false;
        },
        addEvarValue(): string {
            if (this.evarValue) {
                return '_' + this.evarValue;
            }

            return '';
        },
    }
});

</script>

<template>
<template v-if="!isDomainLevelLower">
    <div class="dac-pager-btn" v-if="totalPage > 0">
        <div class="dac-pager-btn__item">
            <a
                :class="['dac-pager-btn__link--prev', pageNo === 1 ? 'is-disabled' : '']"
                href="javascript:void(0)"
                @click="prevPage($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + addEvarValue + '_paging_return_button_onclick_201904')"
            >戻る</a>
        </div>
        <div class="dac-pager-btn__item" v-if="showFirstPageBtn"><a href="javascript:void(0)" class="dac-pager-btn__link" @click="selectPage(1)">1</a></div>
        <div class="dac-pager-btn__item" v-if="showFirstPageBtn"><span class="dac-pager-text">…</span></div>
        <div class="dac-pager-btn__item" v-for="page in pages">
            <span v-if="page === pageNo" class="dac-pager-btn__link is-current">{{ page }}</span>
            <a
                v-else
                href="javascript:void(0)"
                class="dac-pager-btn__link"
                @click="selectPage(page);onclickEvar('eVar10', router.market + 'auc_' + pageName + addEvarValue + '_paging_' + page + '_button_onclick_201904')"
            >{{ page }}</a>
        </div>
        <div class="dac-pager-btn__item" v-if="showLastPageBtn"><span class="dac-pager-text">…</span></div>
        <div class="dac-pager-btn__item" v-if="showLastPageBtn"><a href="javascript:void(0)" class="dac-pager-btn__link" @click="selectPage(totalPage)">{{ totalPage }}</a></div>
        <div class="dac-pager-btn__item">
            <a
                :class="['dac-pager-btn__link--next', pageNo === totalPage ? 'is-disabled' : '']"
                href="javascript:void(0)"
                @click="nextPage($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + addEvarValue + '_paging_next_button_onclick_201904')"
            >次へ</a>
        </div>
    </div><!-- /.dac-pager-btn -->
</template>
</template>