import {
    SET_OFFER_PRICE,
    SET_OFFER_FLAG,
    OFFER_FLAG_NO
} from '../../libs/Mutations';

import Api from "../../libs/Api";

export interface IState {
    offerPrice: number;
    offerFlag: number;
}

export const Buy = {
    state: () => ({
        offerPrice: 0,
        offerFlag: OFFER_FLAG_NO
    } as IState),
    mutations: {
        [SET_OFFER_PRICE](state, offerPrice: number) {
            state.offerPrice = offerPrice;
        },
        [SET_OFFER_FLAG](state, offerFlag: number) {
            state.offerFlag = offerFlag;
        }
    },
    actions: {
        setOfferPrice(context, offerPrice: number): void {
            context.commit(SET_OFFER_PRICE, offerPrice);
        },
        setOfferFlag(context, offerFlag: number): void {
            context.commit(SET_OFFER_FLAG, offerFlag);
        },
        async buyAuctionItem(context, params: InterfaceBuyItemParams): Promise<any> {
            const request: Api = new Api();
            return await request.post(
                'buyAuctionItem',
                params
            );
        },
        clearBuyData(context): void {
            context.commit(SET_OFFER_PRICE, 0);
            context.commit(SET_OFFER_FLAG, OFFER_FLAG_NO);
        }
    }
};
