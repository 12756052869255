<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import Mixin from '../libs/Mixin';

interface IState {

}
export default defineComponent({
    mixins: [Mixin],
    setup() {
        const state = reactive<IState>({

        });

        return {
            ...toRefs(state)
        };
    },
    computed: {
        message(): string {
            return this.$store.state.Common.errorMessage;
        }
    }
});
</script>

<template>
<div>
    <main class="dac-main">
        <article class="dac-article">
            <section class="dac-section">
                <div class="dac-section__inner">
                    <div class="dac-note--alert">
                        <div class="dac-note__inner">
                            <p class="dac-note--alert__text">
                                <span class="dac-text__alert" v-html="message"></span>
                            </p>
                        </div>
                    </div><!-- /.dac-note-notice -->
                </div><!-- /.dac-section__inner -->
            </section><!-- /.dac-section -->
        </article><!-- /.dac-article -->
    </main><!-- /.dac-main -->
</div>
</template>