import mitt from 'mitt';

type Events = {
    [key: string]: any
}

export class EventHub {

    private emitter = mitt<Events>();

    public hasEvent(event: string): boolean {
        return this.emitter.all.has(event);
    }

    public on<T = unknown>(event: string, callback: (arg: T) => void): this {
        this.emitter.on(event, callback);

        return this;
    }

    public once<T = unknown>(event: string, callback: (arg: T) => void): this {
        const onceCallback = (arg) => {
            this.off<T>(event);
            callback(arg);
        };
        this.emitter.on(event, onceCallback);

        return this;
    }

    public off<T>(event: string, callback?: (arg: T) => void): this {
        this.emitter.off(event, callback);

        return this;

    }

    public emit<T>(event: string, arg?: T): this {
        this.emitter.emit(event, arg);

        return this;
    }
}

export default new EventHub();