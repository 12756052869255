import CommonUtil from './CommonUtil';

const globalData: InterfaceGlobalData = CommonUtil.globalData;

// COMMON
export const SET_COMMON_DATA: string = 'SET_COMMON_DATA';
export const SET_COMMON_LOADING: string = 'SET_COMMON_LOADING';
export const SET_MAIN_COMPONENT: string = 'SET_MAIN_COMPONENT';
export const END_INTRO: string = 'END_INTRO';
export const SHOW_MODAL: string = 'SHOW_MODAL';
export const HIDE_MODAL: string = 'HIDE_MODAL';
export const SET_RENDER_HIDE_MODAL: string = 'SET_RENDER_HIDE_MODAL';
export const LOGIN_SUCCESS_EVENT: string = 'LOGIN_SUCCESS_EVENT';
export const SET_AJAX_PROGRESS: string = 'SET_AJAX_PROGRESS';
export const SET_ERROR_MESSAGE: string = 'SET_ERROR_MESSAGE';
export const WATCH_EVENT_LOGIN: string = 'WATCH_EVENT_LOGIN';
export const LOGIN_DEFAULT: string     = 'loginDefault';

// VIEW
export const SET_VIEW_DATA: string = 'SET_VIEW_DATA';

// LISTTRAFFIC_TYPE_SELL
export const SET_LIST_DATA: string = 'SET_LIST_DATA';

// DETAIL
export const SET_ITEM_DATA: string = 'SET_ITEM_DATA';

// HISTORY
export const SET_HISTORY_LIST_DATA: string = 'SET_HISTORY_LIST_DATA';

// RECOMMEND
export const SET_RECOMMEND_LIST_DATA: string = 'SET_RECOMMEND_LIST_DATA';

// WATCH LIST
export const ADD_WATCH_LIST: string = 'ADD_WATCH_LIST';
export const SET_WATCH_LIST_DATA: string = 'SET_WATCH_LIST_DATA';

// BID
export const SET_BID_LIST_DATA: string = 'SET_BID_LIST_DATA';
export const SET_BID_PRICE: string = 'SET_BID_PRICE';
export const SET_BID_STATUS: string = 'SET_BID_STATUS';

// WIN BID
export const SET_WIN_BID_LIST_DATA: string = 'SET_WIN_BID_LIST_DATA';

// OFFER
export const SET_OFFER_LIST_DATA: string = 'SET_OFFER_LIST_DATA';

// BUY
export const SET_OFFER_PRICE: string = 'SET_OFFER_PRICE';
export const SET_OFFER_FLAG: string = 'SET_OFFER_FLAG';

export const OFFER_FLAG_NO: number = 0;
export const OFFER_FLAG_YES: number = 1;
export const TRAFFIC_TYPE_SELL: number = 1;
export const TRAFFIC_TYPE_AUCTION: number = 2;

// Market
export const SET_END_DATE: string = 'SET_END_DATE';

// VARIABLES
export const NEED_AUTH_PAGES: string[]  = [
    'mypage'
];

export const DEFAULT_PAGE_NO: number = globalData.config.DEFAULT_PAGE_NO;
export const DEFAULT_LINE_CNT: number = globalData.config.DEFAULT_LINE_CNT;

export const DEFAULT_ERROR_MESSAGE: string = 'エラーが発生しました。';
export const ERROR_NOT_EXIST_ITEM: string = 'オークション対象ドメインがありません。';
export const COMPONENT_LOGIN: string = 'login';
export const COMPONENT_LIST: string = 'list';
export const COMPONENT_ERROR: string = 'error';
export const COMPONENT_MY_PAGE: string = 'mypage';
export const COMPONENT_DETAIL: string = 'detail';
